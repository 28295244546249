// KCMic Header (KCMic 소개, 자료실, 로그인)
// Created: 2021.03.21
// Updated: 2021.03.30
// Creator: 박지현 

import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Dropdown from '../../utils/Dropdown';
import Transition from '../../utils/Transition.js';

import firebase from './../../fire-admin';

function KCMicHeader() {

    const [mobileNavOpen, setMobileNavOpen] = useState(false);

    const mobileNav = useRef(null);

    // close the mobile menu on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!mobileNavOpen || mobileNav.current.contains(target)) return;
            setMobileNavOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close the mobile menu if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!mobileNavOpen || keyCode !== 27) return;
            setMobileNavOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    // Handle light modes
    const [darkMode, setDarkMode] = useState(() => {
        const dark = localStorage.getItem('dark-mode');
        if (dark === null) {
            return true;
        } else {
            return dark === 'true';
        }
    });

    useEffect(() => {
        localStorage.setItem('dark-mode', darkMode)
        if (darkMode) {
            document.documentElement.classList.remove('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    }, [darkMode]);

    // 관리자 로그인 되어있는 상태인지 체크
    const [state, setState] = useState(false);
    firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
            // User is signed in.
            setState(true);
        } else {
            // No user is signed in.
            setState(false);
        }
    });

    useEffect(() => {
        console.log(firebase.auth().currentUser);
    }, []);

    return (
        <header className="absolute w-full z-30 bg-white">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="flex items-center justify-between h-20">

                    {/* Site branding */}
                    <div className="flex-shrink-0 mr-5">
                        {/* Logo */}
                        <Link to="/" className="block" aria-label="KCMic">
                            <img src={require("./../../images/kcmic-logo-image.png")}
                                alt="KCMIC한국건설자재시험연구원"
                                width={250}
                                height={50}
                            />
                        </Link>
                    </div>

                    {/* Desktop navigation */}
                    <nav className="hidden md:flex md:flex-grow">

                        {/* Desktop menu links */}
                        <ul className="flex flex-grow flex-wrap items-center font-medium">
                            <Dropdown title="KCMIC소개">
                                {/* 2nd level: hover */}
                                <li>
                                    <Link to="/kcmic-about" className="text-base text-gray-600 dark:text-gray-400 hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight">
                                        연구원 소개
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/kcmic-about1" className="text-base text-gray-600 dark:text-gray-400 hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight">
                                        공평성 선언문
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/kcmic-about2" className="text-base text-gray-600 dark:text-gray-400 hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight">
                                        배상책임 서약서
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/kcmic-process" className="text-base text-gray-600 dark:text-gray-400 hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight">
                                        의뢰 업무 절차
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/kcmic-location" className="text-base text-gray-600 dark:text-gray-400 hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight">
                                        찾아오시는 길
                                    </Link>
                                </li>
                            </Dropdown>
                            <li>
                                {firebase.auth().currentUser!==null ? (
                                    <Link to="/kcmic-admin-crud" className="text-xl text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">
                                        자료실
                                    </Link>
                                ) : (
                                    <Link to="/kcmic-crud" className="text-xl text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">
                                        자료실
                                    </Link>
                                )}
                            </li>
                            {/* <li>
                                <Link to="/blog" className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">Blog</Link>
                            </li>
                            <li>
                                <Link to="/testimonials" className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">Testimonials</Link>
                            </li> */}
                            {/* 1st level: hover */}
                            {/* <Dropdown title="Resources">
                                <li>
                                    <Link to="/help" className="text-base text-gray-600 dark:text-gray-400 hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight">Help center</Link>
                                </li>
                                <li>
                                    <Link to="/contact" className="text-base text-gray-600 dark:text-gray-400 hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight">404</Link>
                                </li>
                            </Dropdown> */}
                        </ul>

                        {/* Desktop Admin Login on the right */}

                        <ul className="flex justify-end flex-wrap items-center">
                            <li>
                                {firebase.auth().currentUser !== null ? (
                                    <a className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out"
                                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                            onClick={() => {
                                                firebase.auth().signOut().then(() => {
                                                    console.log('signout!');
                                                    setState(false);
                                                    alert('로그아웃 되었습니다.');
                                                    window.location.replace("/");
                                                });
                                            }}
                                        >
                                            로그아웃
                                    </a>
                                ) : (
                                    <Link to="/kcmic-admin" className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out"
                                        style={{ textDecoration: 'underline' }}>
                                        로그인
                                    </Link>
                                )}
                            </li>
                        </ul>

                    </nav>

                    {/* Mobile menu */}
                    <div className="inline-flex md:hidden">

                        {/* Hamburger button */}
                        <button className={`hamburger ${mobileNavOpen && 'active'}`} aria-controls="mobile-nav" aria-expanded={mobileNavOpen} onClick={() => setMobileNavOpen(!mobileNavOpen)}>
                            <span className="sr-only">Menu</span>
                            <svg className="w-6 h-6 fill-current text-gray-900 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 transition duration-150 ease-in-out" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <rect y="4" width="24" height="2" rx="1" />
                                <rect y="11" width="24" height="2" rx="1" />
                                <rect y="18" width="24" height="2" rx="1" />
                            </svg>
                        </button>

                        {/*Mobile navigation */}
                        <Transition
                            show={mobileNavOpen}
                            tag="ul"
                            className="fixed top-0 h-screen z-20 left-0 w-full max-w-sm -ml-16 overflow-scroll bg-white dark:bg-gray-900 shadow-lg"
                            enter="transition ease-out duration-200 transform"
                            enterStart="opacity-0 -translate-x-full"
                            enterEnd="opacity-100 translate-x-0"
                            leave="transition ease-out duration-200"
                            leaveStart="opacity-100"
                            leaveEnd="opacity-0"
                        >
                            <nav id="mobile-nav" ref={mobileNav} className="fixed top-0 h-screen z-20 left-0 w-full max-w-sm -ml-16 overflow-scroll bg-white dark:bg-gray-900 shadow-lg">
                                <div className="py-6 pr-4 pl-20">
                                    {/* Logo */}
                                    <Link to="/" className="block" aria-label="Cruip">
                                        <img src={require("./../../images/kcmic-logo-image.png")}
                                            alt="KCMIC한국건설자재시험연구원"
                                            width={150}
                                            height={30}
                                            style={{ marginBottom: '30px' }}
                                        />
                                    </Link>

                                    {/* Links */}
                                    <ul>
                                        <li className="py-2 my-2 border-t border-b border-gray-200 dark:border-gray-800">
                                            <span className="flex text-gray-600 dark:text-gray-400 py-2">KCMic 소개</span>
                                            <ul className="pl-4">
                                                <li>
                                                    <Link to="/kcmic-about" className="text-base flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2">
                                                        연구원 소개
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/kcmic-process" className="text-base flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2">
                                                        의뢰 업무 절차
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/kcmic-location" className="text-base flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2">
                                                        찾아오시는 길
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Link to="/kcmic-crud" className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2">
                                                자료실
                                            </Link>
                                        </li>
                                        {/* <li className="py-2 my-2 border-t border-gray-200 dark:border-gray-800">
                                            <Link to="/kcmic-about" className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2"
                                                style={{ textDecoration: 'underline' }}
                                            >
                                                로그인
                                            </Link>
                                        </li> */}
                                    </ul>
                                </div>
                            </nav>
                        </Transition>

                    </div>

                </div>
            </div>
        </header>
    );
}

export default KCMicHeader;
