// KCMic 자료실(CRUD) 메인 항목
// Created: 2021.03.21
// Updated: 2021.04.07
// Creator: 박지현 

import React from 'react';
import { Link } from 'react-router-dom';

function KCMicCRUDMain() {

    return (
        <section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6" style={{marginTop: '40px'}}>
                <div className="pb-12 md:pb-16">

                    <div className="grid gap-4 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4" data-aos-id-featposts>

                        {/* <article className="relative group px-6 py-4 sm:py-8" data-aos="fade-up" data-aos-anchor="[data-aos-id-featposts]" data-aos-delay="100">
                            <figure>
                                <div className="absolute inset-0 bg-teal-500 opacity-75 group-hover:opacity-50 transition duration-700 ease-out" aria-hidden="true"></div>
                            </figure>
                            <div className="relative flex flex-col h-full text-white">
                                <header className="flex-grow">
                                    <Link className="hover:underline" to="/kcmic-crud">
                                        <h3 className="text-lg font-red-hat-display font-bold tracking-tight mb-2">
                                            메인 자료 1
                                        </h3>
                                    </Link>
                                </header>
                                <footer>
                                    <div className="text-sm opacity-80">By 담당자 · Nov 16, 2020</div>
                                </footer>
                            </div>
                        </article>

                        <article className="relative group px-6 py-4 sm:py-8" data-aos="fade-up" data-aos-anchor="[data-aos-id-featposts]" data-aos-delay="200">
                            <figure>
                                <div className="absolute inset-0 bg-purple-500 opacity-75 group-hover:opacity-50 transition duration-700 ease-out" aria-hidden="true"></div>
                            </figure>
                            <div className="relative flex flex-col h-full text-white">
                                <header className="flex-grow">
                                    <Link className="hover:underline" to="/kcmic-crud">
                                        <h3 className="text-lg font-red-hat-display font-bold tracking-tight mb-2">
                                            메인 자료 2
                                        </h3>
                                    </Link>
                                </header>
                                <footer>
                                    <div className="text-sm opacity-80">By 담당자 · Nov 14, 2020</div>
                                </footer>
                            </div>
                        </article>

                        <article className="relative group px-6 py-4 sm:py-8" data-aos="fade-up" data-aos-anchor="[data-aos-id-featposts]" data-aos-delay="300">
                            <figure>
                                <div className="absolute inset-0 bg-indigo-500 opacity-75 group-hover:opacity-50 transition duration-700 ease-out" aria-hidden="true"></div>
                            </figure>
                            <div className="relative flex flex-col h-full text-white">
                                <header className="flex-grow">
                                    <Link className="hover:underline" to="/kcmic-crud">
                                        <h3 className="text-lg font-red-hat-display font-bold tracking-tight mb-2">
                                            메인 자료 3
                                        </h3>
                                    </Link>
                                </header>
                                <footer>
                                    <div className="text-sm opacity-80">By 담당자 · Nov 10, 2020</div>
                                </footer>
                            </div>
                        </article>

                        <article className="relative group px-6 py-4 sm:py-8" data-aos="fade-up" data-aos-anchor="[data-aos-id-featposts]" data-aos-delay="400">
                            <figure>
                                <div className="absolute inset-0 bg-pink-500 opacity-75 group-hover:opacity-50 transition duration-700 ease-out" aria-hidden="true"></div>
                            </figure>
                            <div className="relative flex flex-col h-full text-white">
                                <header className="flex-grow">
                                    <Link className="hover:underline" to="/kcmic-crud">
                                        <h3 className="text-lg font-red-hat-display font-bold tracking-tight mb-2">
                                            메인 자료 4
                                        </h3>
                                    </Link>
                                </header>
                                <footer>
                                    <div className="text-sm opacity-80">By 담당자 · Nov 2, 2020</div>
                                </footer>
                            </div>
                        </article> */}

                    </div>

                </div>
            </div>
        </section>
    );
}

export default KCMicCRUDMain;