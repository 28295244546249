// KCMic 의뢰업무절차 페이지
// Created: 2021.03.21
// Updated: 2021.03.21
// Creator: 박지현 

import React from 'react';

import KCMicHeader from '../../partials/KCMic/KCMicHeader';
import KCMicFooter from '../../partials/KCMic/KCMicFooter';

import KCMicHeroProcess from '../../partials/KCMic/Hero/KCMicHeroProcess';
import KCMicProcessList from '../../partials/KCMic/KCMicProcessList';

function KCMicProcess() {
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <KCMicHeader />

            {/*  Page content */}
            <main className="flex-grow">

                {/*  Page sections */}
                <KCMicHeroProcess />
                <KCMicProcessList />

            </main>

            {/*  Site footer */}
            <KCMicFooter />

        </div>
    );
}

export default KCMicProcess;