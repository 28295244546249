// KCMic 기타 리스트 섹션
// Created: 2021.03.21
// Updated: 2021.03.21
// Creator: 박지현 

import React, { useState } from 'react';

function KCMicEtcList() {

    const [category, setCategory] = useState('');

    return (
        <section className="relative border-t border-transparent dark:border-gray-800">
            {/* Background gradient (dark version only) */}
            <div className="absolute inset-0 opacity-25 bg-gradient-to-b from-gray-800 to-gray-900 pointer-events-none hidden dark:block" aria-hidden="true"></div>
            {/* End background gradient (dark version only) */}
            <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                <div className="py-12 md:py-20">

                    <div data-aos="fade-up" data-aos-delay="450">

                        {/* Category buttons */}
                        <div className="mb-10">
                            <div className="flex flex-wrap justify-center -m-1">
                                <button
                                    className={`font-medium px-4 py-2 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 shadow transition duration-150 ease-in-out rounded-full inline-flex items-center justify-center m-1 ${category === '1' && 'bg-indigo-500 hover:bg-indigo-500 dark:bg-indigo-600 dark:hover:bg-indigo-600 dark:bg-opacity-25 dark:hover:bg-opacity-25'}`}
                                    onClick={() => setCategory('1')}
                                >
                                    <svg className="w-4 h-4 flex-shrink-0 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                        <path className={`fill-current ${category === '1' ? 'text-indigo-200 dark:text-indigo-400' : 'text-indigo-500'}`} d="M1 0h8a1 1 0 011 1v10a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1zm14.124 4.085v-.001a1 1 0 01.868 1.116l-1.243 9.932a1 1 0 01-1.117.868l-7.938-1 .248-1.988 6.946.871.995-7.938-2.007-.251.248-1.984 3 .375z" />
                                    </svg>
                                    <span className={`transition duration-150 ease-in-out ${category === '1' ? 'text-white dark:text-indigo-400' : 'text-gray-600 dark:text-gray-300'}`}>개인정보처리방침</span>
                                </button>
                            </div>
                        </div>

                        {/* Articles */}
                        <div className="-mb-2">

                            {/* 3rd Article */}
                            <article className={`mb-2 ${!['', '1'].includes(category) && 'hidden'}`}>
                                <div className="flex pr-6 py-5 bg-white dark:bg-gray-800 divide-x divide-gray-200 dark:divide-gray-700 shadow-2xl">
                                    {/* Category icon */}
                                    <div className="flex items-center px-4 sm:px-8">
                                        <svg className="w-8 h-8" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                            <circle className="fill-current text-indigo-100 dark:hidden" cx="16" cy="16" r="16" />
                                            <path className="fill-current text-indigo-500" d="M2 2v19h16V2H2zm18-2v23H0V0h20zm9.201 11.03l-7.425-1.987.518-1.932 9.357 2.505-5.948 22.218-19.32-5.172.518-1.932 17.388 4.654L29.2 11.03z" />
                                        </svg>
                                    </div>
                                    {/* Content */}
                                    <div className="pl-6">
                                        <p className="text-gray-600 dark:text-gray-400">
                                            "한국건설자재시험연구원"(은)는 개인정보 보호법 제30조에 따라 정보주체(고객)의 개인정보를 보호하고
                                            이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을
                                            수립, 공개합니다.
                                        </p><br />
                                        <header>
                                            <h3 className="font-red-hat-display text-xl font-extrabold tracking-tighter mb-1">
                                                <a>1. 개인정보의 처리목적</a>
                                            </h3>
                                        </header>
                                        <p className="text-gray-600 dark:text-gray-400">
                                            "한국건설자재시험연구원"은(는) 다음의 목적을 위하여 개인정보를 처리하고 있으며,
                                            다음의 목적 이외의 용도로는 이용하지 않습니다.
                                            <br /><br />
                                            - 고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별․인증,
                                            회원자격 유지․관리, 물품 또는 서비스 공급에 따른 금액 결제, 물품 또는 서비스의 공급․배송 등
                                        </p><br />
                                        <header>
                                            <h3 className="font-red-hat-display text-xl font-extrabold tracking-tighter mb-1">
                                                <a>2. 개인정보의 처리 및 보유기간</a>
                                            </h3>
                                        </header>
                                        <p className="text-gray-600 dark:text-gray-400">
                                            ① "한국건설자재시험연구원"은(는) 정보주체로부터 개인정보를 수집할 때 동의받은 개인정보 보유,
                                            이용기간 또는 법령에 따른 개인정보 보유, 이용기간 내에서 개인정보를 처리, 보유합니다.
                                            <br /><br />
                                            ② 구체적인 개인정보 처리 및 보유 기간은 다음과 같습니다.<br />
                                            - 고객 가입 및 관리 : 서비스 이용계약 또는 회원가입 해지시까지, 다만 채권, 채무관계 잔존시에는 해당 채권,채무관계 정산시까지 <br />
                                            - 전자상거래에서의 계약․청약철회, 대금결제, 재화 등 공급기록 : 5년
                                        </p><br />
                                        <header>
                                            <h3 className="font-red-hat-display text-xl font-extrabold tracking-tighter mb-1">
                                                <a>3. 개인정보의 제3자 제공</a>
                                            </h3>
                                        </header>
                                        <p className="text-gray-600 dark:text-gray-400">
                                            "한국건설자재시험연구원"은(는) 정보주체의 별도 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조에 해당하는 경우 외에는
                                            개인정보를 제3자에게 제공하지 않습니다.
                                        </p><br />
                                        <header>
                                            <h3 className="font-red-hat-display text-xl font-extrabold tracking-tighter mb-1">
                                                <a>4. 정보주체와 법정대리인의 권리, 의무 및 행사방법</a>
                                            </h3>
                                        </header>
                                        <p className="text-gray-600 dark:text-gray-400">
                                            정보주체는 "한국건설자재시험연구원"에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다. <br />
                                            1. 개인정보 열람요구 <br />
                                            2. 개인정보에 오류 등이 있을 경우 정정 요구 <br />
                                            3. 삭제요구 <br />
                                            4. 처리정지 요구
                                        </p><br />
                                        <header>
                                            <h3 className="font-red-hat-display text-xl font-extrabold tracking-tighter mb-1">
                                                <a>5. 처리하는 개인정보 항목</a>
                                            </h3>
                                        </header>
                                        <p className="text-gray-600 dark:text-gray-400">
                                            "한국건설자재시험연구원"은(는) 다음의 개인정보 항목을 처리하고 있습니다. <br />
                                            - 성명, 생년월일, 주소, 전화번호, 휴대전화번호, 성별, 이메일주소, 신용카드번호, 은행계좌번호 등 결제정보
                                        </p><br />
                                        <header>
                                            <h3 className="font-red-hat-display text-xl font-extrabold tracking-tighter mb-1">
                                                <a>6. 개인정보의 파기</a>
                                            </h3>
                                        </header>
                                        <p className="text-gray-600 dark:text-gray-400">
                                            ① "한국건설자재시험연구원"은(는) 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. <br />
                                            ② "한국건설자재시험연구원"은(는) 다음의 방법으로 개인정보를 파기합니다. <br />
                                                - 전자적 파일 : 파일 삭제 및 디스크 등 저장매체 포맷 - 수기(手記) 문서 : 분쇄하거나 소각
                                        </p><br />
                                        <header>
                                            <h3 className="font-red-hat-display text-xl font-extrabold tracking-tighter mb-1">
                                                <a>8. 개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항</a>
                                            </h3>
                                        </header>
                                        <p className="text-gray-600 dark:text-gray-400">
                                            ① "한국건설자재시험연구원"은(는) 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고
                                            수시로 불러오는 ‘쿠키(cookie)’를 사용합니다. <br />
                                            ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며
                                            이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다. <br /><br />
                                            가. 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어,
                                            보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다. <br />
                                            나. 쿠키의 설치∙운영 및 거부 : 웹브라우저 상단의 [도구] - [인터넷 옵션] - [개인정보] 메뉴의 옵션 설정을 통해
                                            쿠키 저장을 거부 할 수 있습니다. <br />
                                            다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
                                        </p><br />
                                        <header>
                                            <h3 className="font-red-hat-display text-xl font-extrabold tracking-tighter mb-1">
                                                <a>9. 개인정보 보호책임자</a>
                                            </h3>
                                        </header>
                                        <p className="text-gray-600 dark:text-gray-400">
                                            "한국건설자재시험연구원"은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의
                                            불만처리 및 피해구제를 처리하기 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. <br />
                                            ▶ 개인정보 보호책임자 성명 : 문상용 직책 : 대표이사 연락처 : 031-491-3002, 031-484-9977
                                        </p><br />
                                        <header>
                                            <h3 className="font-red-hat-display text-xl font-extrabold tracking-tighter mb-1">
                                                <a>10. 개인정보 처리방침 변경 이 개인정보 처리방침은 2021. 03. 31.부터 적용됩니다.</a>
                                            </h3>
                                        </header>
                                    </div>
                                </div>
                            </article>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default KCMicEtcList;