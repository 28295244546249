/* global kakao */

// KCMic 찾아오시는 길 캡션
// Created: 2021.03.22
// Updated: 2021.03.22
// Creator: 박지현 

import React, { useEffect } from 'react';

function KCMicLocationMap() {

    useEffect(() => {
        const script = document.createElement('script');
        script.async = true;
        script.src =
            "https://dapi.kakao.com/v2/maps/sdk.js?appkey=44e9cec334eb8c0b47b0611e81ebf95f&autoload=false";
        document.head.appendChild(script);

        script.onload = () => {
            kakao.maps.load(() => {
                let container = document.getElementById('location-map');
                let options = {
                    center: new kakao.maps.LatLng(37.30616037142726, 126.9248517803252),
                    level: 7
                };

                const map = new window.kakao.maps.Map(container, options);

                // marker가 표시될 위치
                let markerPos = new kakao.maps.LatLng(
                    37.30616037142726, 126.9248517803252
                );

                // marker 생성
                let marker = new kakao.maps.Marker({
                    position: markerPos
                });

                // marker를 지도 위에 표시
                marker.setMap(map);
            })
        }
    }, []);

    return (
        <section className="relative">

            <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pt-22 pb-12 md:pt-40 md:pb-20">

                    <div className="-mb-2">

                        <article className={`mb-2}`}>
                            <div className="flex pr-6 py-5 bg-white dark:bg-gray-800 divide-x divide-gray-200 dark:divide-gray-700 shadow-2xl">

                                {/* Map icon */}
                                <div className="flex items-center px-4 sm:px-8">
                                    <img src={require('./../../images/maps-icon.jpg')} width='130' height='130' alt="maps" />
                                </div>

                                {/* Content */}
                                <div className="pl-6">
                                    <header>
                                        <h3 className="font-red-hat-display text-xl font-extrabold tracking-tighter mb-5">
                                            <a>한국건설자재시험연구원 위치</a>
                                        </h3>
                                    </header>
                                    <p className="text-gray-600 dark:text-gray-400 mb-3">
                                        (우) 15523 경기도 안산시 상록구 사사안골3길 9 (사사동)
                                        </p>
                                    <div className="flex">
                                        <p className="text-gray-600 dark:text-gray-400">
                                            ▶ TEL: 031-419-3002
                                            </p>
                                        <p className="text-gray-600 dark:text-gray-400 ml-9">
                                            ▶ FAX: 031-484-9977
                                            </p>
                                    </div>
                                </div>
                            </div>
                        </article>
                        <div className="mx-auto text-center pb-12 md:pb-20" id="location-map"
                            style={{ height: '65vh' }}
                        >
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default KCMicLocationMap;