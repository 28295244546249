// KCMic Router List
// Created: 2021.03.21
// Updated: 2021.03.30
// Creator: 박지현 

import React, { useEffect } from 'react';
import {
    Switch,
    Route,
    useLocation
} from 'react-router-dom';

import './css/style.scss';

import AOS from 'aos';
import { focusHandling } from 'cruip-js-toolkit';

// KCMic Route
import KCMicHome from './pages/KCMic/KCMicHome';
import KCMicAbout from './pages/KCMic/KCMicAbout';
import KCMicAbout1 from './pages/KCMic/KCMicAbout1';
import KCMicAbout2 from './pages/KCMic/KCMicAbout2';
import KCMicProcess from './pages/KCMic/KCMicProcess';
import KCMicLocation from './pages/KCMic/KCMicLocation';
import KCMicCRUD from './pages/KCMic/KCMicCRUD';
import KCMicCRUDView from './pages/KCMic/KCMicCRUDView';
import KCMicPrivacy from './pages/KCMic/KCMicPrivacy';
import KCMicPopup2 from './pages/KCMic/KCMicPopup2';
import KCMicLogin from './pages/KCMic/KCMicLogin';
import KCMicAdminCRUD from './pages/KCMic/KCMicAdminCRUD';
import KCMicAdminCRUDView from './pages/KCMic/KCMicAdminCRUDView';
import KCMicCRUDWrite from './pages/KCMic/KCMicAdminCRUDWrite';

// 기본 Route
// import Home from './pages/Home';
import Testimonials from './pages/Testimonials';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
// import About from './pages/About';
import Contact from './pages/Contact';
import Help from './pages/Help';
import PageNotFound from './pages/PageNotFound';

function App() {

    const location = useLocation();

    useEffect(() => {
        AOS.init({
            once: true,
            disable: 'phone',
            duration: 750,
            easing: 'ease-out-quart',
        });
    });

    useEffect(() => {
        document.querySelector('html').style.scrollBehavior = 'auto'
        window.scroll({ top: 0 })
        document.querySelector('html').style.scrollBehavior = ''
        focusHandling('outline');
    }, [location.pathname]);        // triggered on route change

    return (
        <>
            <Switch>

                {/* KCMic 홈페이지 메인화면 */}
                <Route exact path="/">
                    <KCMicHome />
                </Route>

                {/* KCMic 메뉴 */}
                <Route path="/kcmic-popup2">
                    <KCMicPopup2 />
                </Route>
                <Route path="/kcmic-about">
                    <KCMicAbout />
                </Route>
                <Route path="/kcmic-about1">
                    <KCMicAbout1 />
                </Route>                
                <Route path="/kcmic-about2">
                    <KCMicAbout2 />
                </Route>
                <Route path="/kcmic-process">
                    <KCMicProcess />
                </Route>
                <Route path="/kcmic-location">
                    <KCMicLocation />
                </Route>
                <Route path="/kcmic-crud">
                    <KCMicCRUD />
                </Route>
                <Route path="/kcmic-crud-view/menu-id/:title/:id">
                    <KCMicCRUDView />
                </Route>
                <Route path="/kcmic-privacy">
                    <KCMicPrivacy />
                </Route>

                {/* KCMic 어드민(admin) 메뉴 */} 
                <Route path="/kcmic-admin">
                    <KCMicLogin />
                </Route>
                <Route path="/kcmic-admin-crud">
                    <KCMicAdminCRUD />
                </Route>
                <Route path="/kcmic-admin-write">
                    <KCMicCRUDWrite />
                </Route>
                <Route path="/kcmic-admin-crud-view/menu-id/:title/:id">
                    <KCMicAdminCRUDView />
                </Route>

                {/* 기본 제공 메뉴 */}
                <Route path="/testimonials">
                    <Testimonials />
                </Route>
                <Route path="/blog">
                    <Blog />
                </Route>
                <Route path="/blog-post">
                    <BlogPost />
                </Route>
                <Route path="/contact">
                    <Contact />
                </Route>
                <Route path="/help">
                    <Help />
                </Route>
                <Route path="*">
                    <PageNotFound />
                </Route>

            </Switch>
        </>
    );
}

export default App;
