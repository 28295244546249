// KCMic 자료실(CRUD) 페이지
// Created: 2021.03.21
// Updated: 2021.03.21
// Creator: 박지현 

import React from 'react';

import KCMicHeader from '../../partials/KCMic/KCMicHeader';
import KCMicFooter from '../../partials/KCMic/KCMicFooter';

import KCMicHeroCRUD from '../../partials/KCMic/Hero/KCMicHeroCRUD';
import KCMicCRUDMain from '../../partials/KCMic/KCMicCRUDMain';
import KCMicCRUDList from '../../partials/KCMic/KCMicCRUDList';

import firebase from './../../fire-admin';

function KCMicCRUD() {

    console.log(firebase.auth().currentUser);

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <KCMicHeader />

            {/*  Page content */}
            <main className="flex-grow">

                {/*  Page sections */}
                <KCMicHeroCRUD />
                <KCMicCRUDMain />
                <KCMicCRUDList />

            </main>

            {/*  Site footer */}
            <KCMicFooter />

        </div>
    );
}

export default KCMicCRUD;