// KCMic 소개 - 연구원 소개: 연구원소개 인사말
// Created: 2021.03.21
// Updated: 2021.03.21
// Creator: 박지현 

import React from 'react';

function KCMicGreetings() {
    return (
        <section className="relative">

            <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pt-32 pb-12 md:pt-40 md:pb-20">
                    <div className="max-w-3xl mx-auto">
                        
                        <article>
                            
                            {/* Article header */}
                            <header className="mb-8">
                                {/* Title and excerpt */}
                                <div className="text-center md:text-left">
                                    <h1 className="h3 font-red-hat-display mb-4" data-aos="fade-down">연구원소개 인사말</h1>
                                    </div>
                            </header>
                            <hr className="w-5 h-px pt-px bg-gray-400 dark:bg-gray-500 border-0 mb-8" data-aos="fade-down" data-aos-delay="450" />

                            {/* Article content */}
                            <div className="text-lg text-gray-600 dark:text-gray-400" data-aos="fade-up" data-aos-delay="450">
                                <p className="mb-8">안녕하십니까?<br />
                                    <strong className="font-medium text-gray-900 dark:text-gray-100">한국건설자재시험연구원입니다</strong>.
                                </p>
                                <p className="mb-8">
                                    저희 연구원은 우수한 기술인력과 최다 시험장비를 보유·운영하고 있는 국내 최고의 민간 품질시험검사 전문회사입니다.
                                </p>
                                <p className="mb-8">
                                    또한 저희 연구원은 KS Q ISO/IES 17025에 준거한 운영시스템을 적용하고 있으며,
                                    민간품질시험검사전문회사 중 『친환경 및 난연성능 관련검사 그리고 공동주택 층간 소
                                    음 완충재 성능시험』 등을 특화 실시함으로써 명실 공히 국내 최고의 품질시험검사 전
                                    문회사로 자리 매김하였습니다.
                                </p>
                                <p className="mb-8">
                                    그리고 저희 연구원은 현대, 삼성, 대우, GS, 대림등 국내 200여 건설회사와 포스코,
                                    현대제철, KCC 및 노루·삼화페인트, 그린피아 스티로폼, SY패널, 대광·동천판넬을
                                    비롯한 300여 건설 자재 생산회사의 품질시험을 담당하고 있으며, LH공사가 전국에서
                                    건설하는 아파트 현장 건설자재 품질시험 중 상당부분을 수행하고 있습니다.
                                </p>
                                <p className="mb-8">
                                    뛰어난 분석기법과 정확한 시험·검사 그리고 성실한 사후관리로 고객 감동경영을 실
                                    현하고 있는 저희 연구원에 건설자재 시험과 현장 시험실 운용업무를 맡겨주시면 최선
                                    을 다하여 귀사의 고품격 품질경영에 동반자가 되어 드릴 것을 약속드립니다.
                                </p>
                                <p className="mb-8">
                                    감사합니다.
                                </p>
                            </div>
                            
                        </article>

                    </div>
                </div>
            </div>

        </section>
    );
}

export default KCMicGreetings;