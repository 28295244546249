// KCMic 자료실(CRUD) 게시글 작성
// Created: 2021.03.23
// Updated: 2021.03.23
// Creator: 박지현 

import React from 'react';

function KCMicHeroCRUDWrite() {

    return (
        <section className="relative">
            
            {/* Background gradient (light version only) */}
            <div className="absolute inset-0 bg-gradient-to-t from-gray-100 to-white pointer-events-none dark:hidden" aria-hidden="true"></div>
            
            {/* End background gradient (light version only) */}
            <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pt-32 pb-12 md:pt-40 md:pb-20">
                    <div className="max-w-3xl mx-auto text-center">
                        <h1 className="h2 font-red-hat-display mb-4" data-aos="fade-down">
                            게시글 작성
                        </h1>
                    </div>
                </div>
            </div>
        
        </section>
    );
}

export default KCMicHeroCRUDWrite;