// KCMic 자료실(CRUD) 리스트업
// Created: 2021.03.21
// Updated: 2021.04.07
// Creator: 박지현 

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';

import firebase from './../../fire-admin';
import moment from 'moment';

function nCompare(a, b) {
    const bandA = a.notice;
    const bandB = b.notice;
  
    let comparison = 0;

    if (bandA < bandB) {
        comparison = 1;
    } else if (bandA > bandB) {
        comparison = -1;
    }

    return comparison;
} 

/*** 내림차순/오름차순 정렬 ***/
function compare(a, b) {
    const bandA = a.created;
    const bandB = b.created;

    let comparison = 0;

    if (bandA < bandB) {
        comparison = 1;
    } else if (bandA > bandB) {
        comparison = -1;
    }

    return comparison;
}

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#6A7372',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
        maxWidth: '170px'
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 300,
    },
});

function timeFormat(time) {
    return `${moment(time.toDate()).format('YYYY-MM-DD')}`;
}

function KCMicCRUDList() {

    moment.locale('kr');

    const classes = useStyles();

    const [info, setInfo] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(1);

    useEffect(() => {
        const list = firebase.firestore().collection('자료실');
        list.onSnapshot((snapshot) => {
            const data = [];
            snapshot.docs.map((doc) => {
                data.push({
                    id: doc.id,
                    ...doc.data()
                });
            });

            setInfo(data);

            if (data.length % 5 === 0) {
                setCount(parseInt(data.length / 5))
            } else {
                setCount(parseInt(data.length / 5 + 1))
            }
        });
    }, []);

    const dataList = [];
    const dataBuff = info.sort(compare);
    dataBuff.sort(nCompare).forEach(data =>
        dataList.push({
            id: data.id,
            ...data
        })
    );

    const handlePage = (event, value) => {
        setPage(value);
    }

    return (
        <section>

            <div className="hidden md:block">
                <div className="max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="pb-12 md:pb-20">
                        {dataList.length !== 0 ? (
                            <>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="left">번호</StyledTableCell>
                                                <StyledTableCell align="left">분류</StyledTableCell>
                                                <StyledTableCell align="left">제목</StyledTableCell>
                                                <StyledTableCell align="right">첨부</StyledTableCell>
                                                <StyledTableCell align="center">작성자</StyledTableCell>
                                                <StyledTableCell align="center">등록일</StyledTableCell>
                                                {/* <StyledTableCell align="center">조회수</StyledTableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dataList.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((row, i) => (
                                                <StyledTableRow key={i}>
                                                    {row.notice ? (
                                                        <StyledTableCell component="th" scope="row">
                                                            공지
                                                        </StyledTableCell>
                                                    ) : (
                                                        <StyledTableCell component="th" scope="row">
                                                            {dataList.length - i}
                                                        </StyledTableCell>
                                                    )}
                                                    <StyledTableCell align="left">
                                                        {row.option}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        <Link className="hover:underline"
                                                            to={`/kcmic-crud-view/menu-id/${row.title}/${row.id}`}
                                                            style={{
                                                                display: 'block',
                                                                textOverflow: 'ellipsis',
                                                                overflow: 'hidden',
                                                                whiteSpace: 'nowrap'
                                                            }}
                                                        >
                                                            {row.title}
                                                        </Link>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        <a href={row.fileUrl[0]}>
                                                            <img src={require('./../../images/floppy-disk.svg')} width="13" height="13" alt="첨부파일"
                                                                style={{ float: 'right', marginRight: '7px' }}
                                                            />
                                                        </a>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">{row.writer}</StyledTableCell>
                                                    <StyledTableCell align="center">{timeFormat(row.created)}</StyledTableCell>
                                                    {/* <StyledTableCell align="center">{row.count}</StyledTableCell> */}
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Pagination
                                    count={count}
                                    page={page}
                                    onChange={handlePage}
                                    style={{
                                        display: 'flex',
                                        marginTop: '30px',
                                        justifyContent: 'center',
                                        color: '#000000'
                                    }}
                                />
                            </>
                        ) : (
                            <div className="dark bg-gray-600 py-8 px-8 md:py-12 md:px-12 text-center text-gray-100 opacity-3" data-aos="fade-down">
                                <div className="text-xl font-medium mb-6">현재 게시된 글이 없습니다.</div>
                                <Link className="btn-sm text-white bg-teal-500 hover:bg-teal-400 flex-shrink-0" to="/">돌아가기</Link>   
                            </div>
                        )}

                    </div>
                </div>
            </div>

            {/* Mobile menu */}
            <div className="md:hidden">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 mt-0">

                    <div className="pb-12 md:pb-20">
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">제목</StyledTableCell>
                                        <StyledTableCell align="center">등록일</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataList.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((row, i) => (
                                        <StyledTableRow key={i}>
                                            <StyledTableCell align="left">
                                                <Link className="hover:underline"
                                                    to={`/kcmic-crud-view/menu-id/${row.title}/${row.id}`}
                                                    style={{
                                                        display: 'block',
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap'
                                                    }}
                                                >
                                                    {row.title}
                                                </Link>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{timeFormat(row.created)}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination
                            count={count}
                            page={page}
                            onChange={handlePage}
                            style={{
                                display: 'flex',
                                marginTop: '30px',
                                justifyContent: 'center',
                                color: '#000000'
                            }}
                        />
                    </div>
                </div>
            </div>

        </section>
    );
}

export default KCMicCRUDList;