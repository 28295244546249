// KCMic Admin용 자료실(CRUD) 세부 내용 페이지
// Created: 2021.03.27
// Updated: 2021.03.27
// Creator: 박지현 

import React from 'react';
import { useParams } from 'react-router';

import KCMicHeader from '../../partials/KCMic/KCMicHeader';
import KCMicFooter from '../../partials/KCMic/KCMicFooter';

import KCMicHeroCRUD from '../../partials/KCMic/Hero/KCMicHeroCRUD';
import KCMicAdminCRUDContent from '../../partials/KCMic/Admin/KCMicAdminCRUDContent';

function KCMicAdminCRUDView() {

    const params = useParams();
    console.log(params);

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <KCMicHeader />

            {/*  Page content */}
            <main className="flex-grow">

                {/*  Page sections */}
                <KCMicHeroCRUD />
                <KCMicAdminCRUDContent data={params} />

            </main>

            {/*  Site footer */}
            <KCMicFooter />

        </div>
    );
}

export default KCMicAdminCRUDView;