// KCMic Home 페이지 - 상단
// Created: 2021.03.21
// Updated: 2021.04.07
// Creator: 박지현 

import React from 'react';

function setCookie(cname, value, expire) {
    var todayValue = new Date();

    todayValue.setDate(todayValue.getDate() + expire);
    document.cookie = cname + "=" + encodeURI(value) + "; expires=" + todayValue.toTimeString() + ";path=/;";
}

function KCMicPopup2() {

    const handleClick = () => {
        setCookie("popup2021", "end", 1);
        window.close();
    }

    return (
        <div id="img" style={{position: 'relative'}}>
            <div style={{position: 'absolute', right: '0', marginRight: '5px', bottom: '0px', marginBottom: '15px'}}>
                <label className="flex items-center"
                        style={{
                            fontSize: 14,
                        }}
                    >
                    <input type="checkbox" className="form-checkbox" onChange={handleClick} />
                    <span className="ml-2">다시 보지 않기</span>
                </label>
            </div>
            <img className="w-full h-full" src={require('../../images/browser-notice.png')} alt="KCMIC브라우저안내" />
        </div>
    );
}

export default KCMicPopup2;