// KCMic 의뢰업무절차 과정
// Created: 2021.03.21
// Updated: 2021.04.07
// Creator: 박지현 

import React from 'react';

function KCMicProcessList() {
    return (
        <section className="relative">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="py-12 md:py-20 border-t border-gray-200 dark:border-gray-800">

                    {/* Section header */}
                    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                        <p className="text-xl text-gray-600">
                            다음과 같은 절차를 통해 품질 검사가 이루어집니다.
                        </p>
                    </div>

                    {/* 고객 - 시료채취 */}
                    <div className="max-w-xs sm:max-w-lg md:max-w-3xl lg:max-w-5xl mx-auto items-center text-center">
                        <div
                            className={`font-medium text-sm px-4 py-1 bg-white hover:bg-gray-50 shadow transition duration-150 ease-in-out rounded-full 
                                mb-5 inline-flex items-center justify-center m-1 bg-indigo-500 hover:bg-indigo-500`}
                        >
                            <span className={`transition duration-150 ease-in-out text-white`}>고객</span>
                        </div>
                        <div data-aos="fade-down" data-aos-anchor="[data-aos-id-team]">
                            <span className="h4 w-25 truncate">시료채취</span>
                        </div>
                    </div>

                    {/* process arrow */}
                    <div className="flex max-w-xs sm:max-w-lg md:max-w-3xl lg:max-w-5xl mx-auto mt-10 mb-10 justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 13l-7 7-7-7m14-8l-7 7-7-7" />
                        </svg>
                    </div>

                    {/* 고객 - 시험 의뢰서 작성 */}
                    <div className="max-w-xs sm:max-w-lg md:max-w-3xl lg:max-w-5xl mx-auto items-center text-center">
                        <div
                            className={`font-medium text-sm px-4 py-1 bg-white hover:bg-gray-50 shadow transition duration-150 ease-in-out rounded-full 
                                mb-5 inline-flex items-center justify-center m-1 bg-indigo-500 hover:bg-indigo-500`}
                        >
                            <span className={`transition duration-150 ease-in-out text-white`}>고객</span>
                        </div>
                        <div data-aos="fade-down" data-aos-anchor="[data-aos-id-team]">
                            <span className="h4 w-25 truncate">시험 의뢰서 작성</span>
                        </div>
                    </div>

                    {/* process arrow */}
                    <div className="flex max-w-xs sm:max-w-lg md:max-w-3xl lg:max-w-5xl mx-auto mt-10 mb-10 justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 13l-7 7-7-7m14-8l-7 7-7-7" />
                        </svg>
                    </div>

                    {/* 한국건설자재시험연구원 - 의뢰서 및 시료 확인 */}
                    <div className="max-w-xs sm:max-w-lg md:max-w-3xl lg:max-w-5xl mx-auto items-center text-center">
                        <div
                            className={`font-medium text-sm px-4 py-1 bg-white hover:bg-gray-50 shadow transition duration-150 ease-in-out rounded-full 
                                mb-5 inline-flex items-center justify-center m-1 bg-teal-500 hover:bg-teal-500`}
                        >
                            <span className={`transition duration-150 ease-in-out text-white`}>한국건설자재시험연구원</span>
                        </div>
                        <div data-aos="fade-down" data-aos-anchor="[data-aos-id-team]">
                            <span className="h4 w-25 truncate">의뢰서 및 시료 확인</span>
                        </div>
                    </div>

                    {/* process arrow */}
                    <div className="flex max-w-xs sm:max-w-lg md:max-w-3xl lg:max-w-5xl mx-auto mt-10 mb-10 justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 13l-7 7-7-7m14-8l-7 7-7-7" />
                        </svg>
                    </div>

                    {/* 한국건설자재시험연구원 - 수수료 책정 및 고지 */}
                    <div className="max-w-xs sm:max-w-lg md:max-w-3xl lg:max-w-5xl mx-auto items-center text-center">
                        <div
                            className={`font-medium text-sm px-4 py-1 bg-white hover:bg-gray-50 shadow transition duration-150 ease-in-out rounded-full 
                                mb-5 inline-flex items-center justify-center m-1 bg-teal-500 hover:bg-teal-500`}
                        >
                            <span className={`transition duration-150 ease-in-out text-white`}>한국건설자재시험연구원</span>
                        </div>
                        <div data-aos="fade-down" data-aos-anchor="[data-aos-id-team]">
                            <span className="h4 w-25 truncate">수수료 책정 및 고지</span>
                        </div>
                    </div>
                    
                    {/* process arrow */}
                    <div className="flex max-w-xs sm:max-w-lg md:max-w-3xl lg:max-w-5xl mx-auto mt-10 mb-10 justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 13l-7 7-7-7m14-8l-7 7-7-7" />
                        </svg>
                    </div>
                    
                    {/* 고객 - 수수료 납부 */}
                    <div className="max-w-xs sm:max-w-lg md:max-w-3xl lg:max-w-5xl mx-auto items-center text-center">
                        <div
                            className={`font-medium text-sm px-4 py-1 bg-white hover:bg-gray-50 shadow transition duration-150 ease-in-out rounded-full 
                                mb-5 inline-flex items-center justify-center m-1 bg-indigo-500 hover:bg-indigo-500`}
                        >
                            <span className={`transition duration-150 ease-in-out text-white`}>고객</span>
                        </div>
                        <div data-aos="fade-down" data-aos-anchor="[data-aos-id-team]">
                            <span className="h4 w-25 truncate">수수료 납부</span>
                        </div>
                    </div>

                    {/* process arrow */}
                    <div className="flex max-w-xs sm:max-w-lg md:max-w-3xl lg:max-w-5xl mx-auto mt-10 mb-10 justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 13l-7 7-7-7m14-8l-7 7-7-7" />
                        </svg>
                    </div>
                    
                    {/* 한국건설자재시험연구원 - 품질시험 & 검사 실시 */}
                    <div className="max-w-xs sm:max-w-lg md:max-w-3xl lg:max-w-5xl mx-auto items-center text-center">
                        <div
                            className={`font-medium text-sm px-4 py-1 bg-white hover:bg-gray-50 shadow transition duration-150 ease-in-out rounded-full 
                                mb-5 inline-flex items-center justify-center m-1 bg-teal-500 hover:bg-teal-500`}
                        >
                            <span className={`transition duration-150 ease-in-out text-white`}>한국건설자재시험연구원</span>
                        </div>
                        <div data-aos="fade-down" data-aos-anchor="[data-aos-id-team]">
                            <span className="h4 w-25 truncate">품질 시험 & 검사 실시</span>
                        </div>
                    </div>
                    
                    {/* process arrow */}
                    <div className="flex max-w-xs sm:max-w-lg md:max-w-3xl lg:max-w-5xl mx-auto mt-10 mb-10 justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 13l-7 7-7-7m14-8l-7 7-7-7" />
                        </svg>
                    </div>
                    
                    {/* 한국건설자재시험연구원 - 시험성과 기록 및 성적서 작성 */}
                    <div className="max-w-xs sm:max-w-lg md:max-w-3xl lg:max-w-5xl mx-auto items-center text-center">
                        <div
                            className={`font-medium text-sm px-4 py-1 bg-white hover:bg-gray-50 shadow transition duration-150 ease-in-out rounded-full 
                                mb-5 inline-flex items-center justify-center m-1 bg-teal-500 hover:bg-teal-500`}
                        >
                            <span className={`transition duration-150 ease-in-out text-white`}>한국건설자재시험연구원</span>
                        </div>
                        <div data-aos="fade-down" data-aos-anchor="[data-aos-id-team]">
                            <span className="h4 w-25 truncate">시험성과 기록 및 성적서 작성</span>
                        </div>
                    </div>
                    
                    {/* process arrow */}
                    <div className="flex max-w-xs sm:max-w-lg md:max-w-3xl lg:max-w-5xl mx-auto mt-10 mb-10 justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 13l-7 7-7-7m14-8l-7 7-7-7" />
                        </svg>
                    </div>

                    {/* 한국건설자재시험연구원 - 시험성적서발송 */}
                    <div className="max-w-xs sm:max-w-lg md:max-w-3xl lg:max-w-5xl mx-auto items-center text-center">
                        <div
                            className={`font-medium text-sm px-4 py-1 bg-white hover:bg-gray-50 shadow transition duration-150 ease-in-out rounded-full 
                                mb-5 inline-flex items-center justify-center m-1 bg-teal-500 hover:bg-teal-500`}
                        >
                            <span className={`transition duration-150 ease-in-out text-white`}>한국건설자재시험연구원</span>
                        </div>
                        <div data-aos="fade-down" data-aos-anchor="[data-aos-id-team]">
                            <span className="h4 w-25 truncate">시험 성적서 발송</span>
                        </div>
                    </div>

                    {/* process arrow */}
                    <div className="flex max-w-xs sm:max-w-lg md:max-w-3xl lg:max-w-5xl mx-auto mt-10 mb-10 justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 13l-7 7-7-7m14-8l-7 7-7-7" />
                        </svg>
                    </div>

                    {/* 고객 - 고객 수령 */}
                    <div className="max-w-xs sm:max-w-lg md:max-w-3xl lg:max-w-5xl mx-auto items-center text-center">
                        <div
                            className={`font-medium text-sm px-4 py-1 bg-white hover:bg-gray-50 shadow transition duration-150 ease-in-out rounded-full 
                                mb-5 inline-flex items-center justify-center m-1 bg-indigo-500 hover:bg-indigo-500`}
                        >
                            <span className={`transition duration-150 ease-in-out text-white`}>고객</span>
                        </div>
                        <div data-aos="fade-down" data-aos-anchor="[data-aos-id-team]">
                            <span className="h4 w-25 truncate">고객 수령</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default KCMicProcessList;