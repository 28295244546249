// KCMic Home 페이지 - 상단
// Created: 2021.03.21
// Updated: 2021.03.21
// Creator: 박지현 

import React from 'react';

function KCMicHeroHome() {

    return (
        <section>
            <div className="hidden md:block max-w-6xl mx-auto px-4 sm:px-6" style={{ marginBottom: '20.5rem' }}>
                <div className="pt-32 pb-10 md:pt-40 md:pb-16">

                    {/* Background image */}
                    <div className="absolute inset-0 h-128 pt-16 mt-30 box-content -z-1">
                        <img className="absolute inset-0 w-full h-full object-cover opacity-95" src={require('../../../images/skyscraper_desktop.jpg')} width="1440" height="577" alt="Testimonials" />
                        {/* <div className="absolute inset-0 bg-gradient-to-t from-white dark:from-gray-900" aria-hidden="true"></div> */}
                    </div>

                    {/* Hero content */}
                    <div className="md:grid md:grid-cols-12 md:gap-12 lg:gap-20 items-center">

                        {/* Content */}
                        <div className="md:col-span-7 lg:col-span-7 mb-8 md:mb-0 text-center md:text-left">
                            {/* <h1 className="h1 lg:text-6xl mb-4 font-red-hat-display font-extrabold" data-aos="fade-down">hi</h1> */}

                            {/* <ul className="max-w-sm sm:max-w-md mx-auto md:max-w-none text-gray-600 dark:text-gray-400 mt-8 -mb-2" data-aos="fade-down" data-aos-delay="450">
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-teal-400 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <p className="text-xl text-gray-600 dark:text-gray-400" data-aos="fade-down" data-aos-delay="150">
                                        한국건설자재시험연구원은 국가공인 품질검사 전문기관입니다.
                                    </p>
                                </li>
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-teal-400 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <p className="text-xl text-gray-600 dark:text-gray-400" data-aos="fade-down" data-aos-delay="150">
                                        한국건설자재시험연구원은 최고를 지향합니다.
                                    </p>
                                </li>
                            </ul> */}
                        </div>

                        {/* Mobile mockup */}
                        <div className="md:col-span-5 lg:col-span-5 text-center md:text-right" data-aos="fade-up" data-aos-delay="450">
                            <div className="inline-flex relative justify-center items-center">
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* Mobile menu */}
            <div className="inline-flex md:hidden" style={{ marginBottom: '16.5rem' }}>
                <div className="pt-32 pb-10 md:pt-40 md:pb-16">

                    {/* Background image */}
                    <div className="absolute inset-0 h-128 pt-16 mt-30 box-content -z-1">
                        <img className="absolute inset-0 w-full h-3/4 object-cover opacity-95" src={require('../../../images/skyscraper_desktop.jpg')} width="720" height="288" alt="Testimonials" />
                        {/* <div className="absolute inset-0 bg-gradient-to-t from-white dark:from-gray-900" aria-hidden="true"></div> */}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default KCMicHeroHome;