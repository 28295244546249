// KCMic About 페이지
// Created: 2021.03.21
// Updated: 2021.03.21
// Creator: 박지현 

import React from 'react';

import KCMicHeader from '../../partials/KCMic/KCMicHeader';
import KCMicFooter from '../../partials/KCMic/KCMicFooter';

import KCMicHeroAbout1 from '../../partials/KCMic/Hero/KCMicHeroAbout1';
import KCMicGreetings1 from '../../partials/KCMic/KCMicGreetings1';

function KCMicAbout() {
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <KCMicHeader />

            {/*  Page content */}
            <main className="flex-grow">

                {/*  Page sections */}
                <KCMicHeroAbout1 />
                <KCMicGreetings1 />

            </main>

            {/*  Site footer */}
            <KCMicFooter />

        </div>
    );
}

export default KCMicAbout;