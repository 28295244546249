// KCMic About 페이지
// Created: 2021.03.21
// Updated: 2021.03.21
// Creator: 박지현 

import React from 'react';

import KCMicHeader from '../../partials/KCMic/KCMicHeader';
import KCMicFooter from '../../partials/KCMic/KCMicFooter';

import KCMicHeroAbout2 from '../../partials/KCMic/Hero/KCMicHeroAbout2';
import KCMicGreetings2 from '../../partials/KCMic/KCMicGreetings2';

function KCMicAbout() {
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <KCMicHeader />

            {/*  Page content */}
            <main className="flex-grow">

                {/*  Page sections */}
                <KCMicHeroAbout2 />
                <KCMicGreetings2 />

            </main>

            {/*  Site footer */}
            <KCMicFooter />

        </div>
    );
}

export default KCMicAbout;