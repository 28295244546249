// KCMic Admin 로그인 페이지
// Created: 2021.03.23
// Updated: 2021.03.23
// Creator: 박지현 

import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';

import KCMicHeader from '../../partials/KCMic/KCMicHeader';
import KCMicFooter from '../../partials/KCMic/KCMicFooter';

import firebase from '../../fire-admin';

function KCMicLogin() {

    const history = useHistory();

    const [adminId, setAdminId] = useState("");     // 관리자 Id
    const [adminPw, setAdminPw] = useState("");     // 관리자 Pw

    const handleLogin = useCallback(async (e) => {
        e.preventDefault();

        const id = adminId + '@gmail.com'

        if (adminId.length > 0 && adminPw.length > 0) {
            try {
                await firebase.auth().signInWithEmailAndPassword(id, adminPw).then(function (auth) {
                    if (firebase.auth().currentUser.email === id) {
                        console.log('success');
                        alert("한국건설자재시험연구원에 관리자로 로그인되었습니다.");
                        history.push('/');
                    }
                });
            } catch (err) {
                if (err.code === "auth/user-not-found" || err.code === "auth/invalid-email") {
                    alert("아이디가 일치하지 않습니다.");

                    // id, pw 칸 초기화
                    document.getElementById('adminId').value = null;
                    document.getElementById('adminPw').value = null;
                } else if (err.code === "auth/wrong-password") {
                    alert("비밀번호가 일치하지 않습니다.");

                    // id, pw 칸 초기화
                    document.getElementById('adminId').value = null;
                    document.getElementById('adminPw').value = null;
                }
            }
        } else {
            alert("아이디와 비밀번호를 입력해주세요.")
        }
    }, [adminId, adminPw, history]);

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <KCMicHeader />

            {/*  Page content */}
            <main className="flex-grow">

                <section className="relative">
                    <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
                        <div className="pt-32 pb-12 md:pt-40 md:pb-20">

                            {/* Page header */}
                            <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                                <h1 className="h3 font-red-hat-display mb-4">관리자 로그인</h1>
                                <p className="text-xl text-gray-600 dark:text-gray-400">권한이 있는 관리자만 로그인 가능합니다.</p>
                            </div>

                            {/* Contact form */}
                            <form className="max-w-sm mx-auto">
                                <div className="flex flex-wrap -mx-3 mb-5">
                                    <div className="w-full px-3">
                                        <label className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1" htmlFor="company">
                                            아이디(Id) <span className="text-red-600">*</span></label>
                                        <input id="adminId" type="text" className="form-input w-full" placeholder="관리자 아이디를 입력하세요." required
                                            onChange={(e) => setAdminId(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-5">
                                    <div className="w-full px-3">
                                        <label className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1" htmlFor="phone">
                                            비밀번호(Password) <span className="text-red-600">*</span></label>
                                        <input id="adminPw" type="password" className="form-input w-full" placeholder="관리자 비밀번호를 입력하세요." required
                                            onChange={(e) => setAdminPw(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mt-6">
                                    <div className="w-full px-3">
                                        <button className="btn text-white bg-teal-500 hover:bg-teal-400 w-full flex items-center"
                                            onClick={handleLogin}
                                        >
                                            <span>로그인</span>
                                            <svg className="w-3 h-3 flex-shrink-0 mt-px ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path className="fill-current" d="M6.602 11l-.875-.864L9.33 6.534H0v-1.25h9.33L5.727 1.693l.875-.875 5.091 5.091z" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>

            </main>

            {/*  Site footer */}
            <KCMicFooter />

        </div>
    );
}

export default KCMicLogin;