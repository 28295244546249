// KCMic Footer
// Created: 2021.03.21
// Updated: 2021.03.21
// Creator: 박지현 

import React from 'react';
import { Link } from 'react-router-dom';

function KCMicFooter() {
    return (
        <footer className="relative">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="py-12 md:py-16 border-t border-gray-200 dark:border-gray-800 -mt-px">

                    {/* Top area: Blocks */}
                    <div className="grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12">

                        {/* 1st block */}
                        <div className="md:col-span-2 lg:col-span-3">
                            {/* Logo */}
                            <Link className="inline-block" to="/" aria-label="Cruip">
                                <img src={require("./../../images/kcmic-logo-image.png")}
                                    alt="KCMIC한국건설자재시험연구원"
                                    width={250}
                                    height={50}
                                />
                            </Link>
                        </div>

                        {/* 2nd, 3rd blocks */}
                        <div className="md:col-span-10 lg:col-span-9 grid grid-cols-2">

                            {/* 2nd block */}
                            <div className="text-sm">
                                <h6 className="font-medium uppercase mb-2">한국건설자재시험연구원(주) | 사업자번호: 124-86-52792</h6>
                                <ul>
                                    <li className="mb-1">대표이사: 박형근</li>
                                    <li className="mb-1">원장: 임동균</li>
                                </ul>
                            </div>

                            {/* 3rd block */}
                            <div className="text-sm">
                                <h6 className="font-medium uppercase mb-2">경기도 안산시 상록구 사사안골 3길 9</h6>
                                <ul>
                                    <li className="mb-1">TEL: 031-419-3002</li>
                                    <li className="mb-1">FAX: 031-484-9977</li>
                                </ul>
                            </div>

                        </div>

                    </div>

                    {/* Bottom area */}
                    <div className="md:flex md:items-center md:justify-between">

                        {/* Middle links */}
                        <div className="text-sm md:order-1 text-gray-700 mb-2 md:mb-0">
                            <Link className="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out" 
                                to="/kcmic-privacy"
                            >
                                개인정보처리방침
                            </Link>&nbsp; · &nbsp;
                            <Link className="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out" 
                                to="/kcmic-location">
                                오시는 길
                            </Link>
                        </div>

                        {/* Copyrights note */}
                        <div className="text-gray-600 dark:text-gray-400 text-sm mr-4">
                            &copy; 2021 KCMIC. All rights reserved.
                        </div>

                    </div>

                </div>
            </div>
        </footer>
    );
}

export default KCMicFooter;
