// KCMic Admin 자료실(CRUD) 글쓰기
// Created: 2021.03.23
// Updated: 2021.03.27
// Creator: 박지현 

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TalbeCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import firebase from './../../../fire-admin';

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: 15,
        disply: 'flex'
    },
    body: {
        fontSize: 15,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 300,
    },
});


function KCMicAdminCRUDCreate() {

    const classes = useStyles();
    const history = useHistory();

    const storage = firebase.storage();

    const [title, setTitle] = useState('');                 // 게시글 제목
    const [option, setOption] = useState('각종서식');         // 게시글 분류
    const [attach, setAttach] = useState('');               // 게시글 첨부파일
    const [content, setContent] = useState('');             // 게시글 세부내용
    const [writer, setWriter] = useState('');               // 게시글 작성자(담당자)
    const [notice, setNotice] = useState(false);            // 공지사항 여부

    const [url, setUrl] = useState("");
    const [progress, setProgress] = useState(0);

    var urlArr = [];
    var attachArr = [];

    const handleChange = (e) => {
        if (e.target.files) {
            let file = e.target.files;
            document.getElementById('file-progress').style.display = 'block';

            for(var i=0; i<file.length; i++) {
                uploadFiles(file[i]);
            }

            setUrl(urlArr);
            setAttach(attachArr);
        }
    }

    const uploadFiles = (file) => {
        const uploadTask = storage.ref(`자료실/${file.name}`).put(file);
        uploadTask.on(
            "state_change",
            snapshot => {
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(progress);

                if (progress === 100) {
                    document.getElementById('file-progress').style.display = 'none';
                }
            },
            error => {
                console.log(error);
            },
            () => {
                storage.ref("자료실")
                    .child(file.name)
                    .getDownloadURL()
                    .then(url => {
                        urlArr.push(url);
                        attachArr.push(file.name);
                    })
            }
        );
    }

    const handleUpload = (e) => {
        console.log(title, option, attach, url, content, writer);
        if(!title || !option || !url || !content || !writer) {
            alert("모든 항목을 입력바랍니다.");
        } else if(title && option && url && content && writer) {
            firebase.firestore().collection('자료실').add({
                created: firebase.firestore.FieldValue.serverTimestamp(),
                title: title,
                option: option,
                fileUrl: url,
                fileName: attach,
                content: content,
                writer: writer,
                count: 0, 
                notice: notice
            }).then(function() {
                console.log("Document successfully written");
                alert("성공적으로 게시되었습니다.");
                history.push("/kcmic-admin-crud");
            }).catch(function(err) {
                console.log("Error writing document: ", err);
            });
        }
    }

    const handleNotice = (e) => {
        setNotice(e.target.checked);
    }

    return (
        <section style={{ marginTop: '100px' }}>

            <div className="max-w-6xl mx-auto px-4 sm:px-6 hidden md:block">
                <div className="pb-12 md:pb-20">
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableBody>

                                {/* 제목 */}
                                <StyledTableRow >
                                    <StyledTableCell component="th" scope="row" style={{ width: '100px' }}>
                                        제목
                                        </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <div className="w-full px-3">
                                            <input id="crud-title" type="text" className="form-input w-full"
                                                placeholder="게시글 제목을 입력하세요." required 
                                                onChange={(e) => setTitle(e.target.value)}    
                                            />
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>

                                {/* 게시글 분류 */}
                                <StyledTableRow >
                                    <StyledTableCell component="th" scope="row" style={{ width: '100px' }}>
                                        분류
                                        </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <div className="w-2/5 md:max-w-full px-3">
                                            <select id="crud-option" className="form-select w-full" required
                                                onChange={(e) => setOption(e.target.value)}
                                            >
                                                <option>각종서식</option>
                                                <option>부서업무</option>
                                                <option>기타</option>
                                            </select>
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>

                                {/* 첨부파일 */}
                                <StyledTableRow >
                                    <StyledTableCell component="th" scope="row" style={{ width: '100px' }}>
                                        첨부파일
                                        </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <div className="w-2/5 px-3">
                                            <input id="crud-file" type="file" multiple className="form-input w-full" required
                                                onChange={handleChange}
                                            />
                                            <div id="file-progress" className="relative pt-1" style={{ display: 'none' }}>
                                                <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
                                                    <div style={{ width: `${progress}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"></div>
                                                </div>
                                            </div>
                                        </div>

                                    </StyledTableCell>
                                </StyledTableRow>

                                {/* 내용 */}
                                <StyledTableRow >
                                    <StyledTableCell component="th" scope="row" style={{ width: '100px' }}>
                                        세부내용
                                        </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <div className="w-full px-3">
                                            <textarea id="message" rows="4" className="form-textarea w-full"
                                                placeholder="세부내용을 입력하세요."
                                                onChange={(e) => setContent(e.target.value)}   
                                            ></textarea>
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>

                                {/* 작성자 */}
                                <StyledTableRow >
                                    <StyledTableCell component="th" scope="row" style={{ width: '100px' }}>
                                        작성자
                                        </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <div className="w-2/5 px-3">
                                            <input id="crud-title" type="text" className="form-input w-full"
                                                placeholder="게시글 제목을 입력하세요." required 
                                                onChange={(e) => setWriter(e.target.value)}    
                                            />
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <label className="flex items-center"
                            style={{
                                fontSize: 14,
                                width: '200px',
                                float: 'left',
                                marginTop: '20px',
                                marginBottom: '20px'
                            }}
                        >
                        <input type="checkbox" className="form-checkbox" onChange={handleNotice} />
                        <span className="ml-2">공지사항으로 설정</span>
                    </label>
                    <a className="btn text-white bg-indigo-700 hover:bg-indigo-400 items-center"
                        onClick={handleUpload}
                        style={{
                            fontSize: 14,
                            width: '115px',
                            float: 'right',
                            marginTop: '20px',
                            marginBottom: '20px',
                            cursor: 'pointer'
                        }}
                    >
                        <span>게시하기</span>
                    </a>
                </div>
            </div>

            {/* Mobile menu */}
            <div className="md:hidden">
                <div className="max-w-6xl mx-auto px-4 sm:px-6">

                    <div className="pb-12 md:pb-20">
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableBody>

                                    {/* 제목 */}
                                    <StyledTableRow >
                                        <StyledTableCell component="th" scope="row" style={{ width: '100px' }}>
                                            제목
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <div className="w-full px-3">
                                                <input id="crud-title" type="text" className="form-input w-full"
                                                    placeholder="게시글 제목을 입력하세요." required />
                                            </div>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    {/* 게시글 분류 */}
                                    <StyledTableRow >
                                        <StyledTableCell component="th" scope="row" style={{ width: '100px' }}>
                                            분류
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <div className="w-full md:max-w-full px-3">
                                                <select id="crud-option" className="form-select w-full" required>
                                                    <option>각종서식</option>
                                                    <option>부서업무</option>
                                                    <option>기타</option>
                                                </select>
                                            </div>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    {/* 첨부파일 */}
                                    <StyledTableRow >
                                        <StyledTableCell component="th" scope="row" style={{ width: '100px' }}>
                                            첨부파일
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <div className="w-full px-3">
                                                <input id="crud-file" type="file" className="form-input w-full" required />
                                            </div>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    {/* 내용 */}
                                    <StyledTableRow >
                                        <StyledTableCell component="th" scope="row" style={{ width: '100px' }}>
                                            세부내용
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <div className="w-full px-3">
                                                <textarea id="message" rows="4" className="form-textarea w-full"
                                                    placeholder="세부내용을 입력하세요."></textarea>
                                            </div>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    {/* 작성자 */}
                                    <StyledTableRow >
                                        <StyledTableCell component="th" scope="row" style={{ width: '100px' }}>
                                            작성자
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <div className="w-full px-3">
                                                <input id="crud-writer" type="text" className="form-input w-full"
                                                    placeholder="작성자 성함을 입력하세요." required />
                                            </div>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <label className="flex items-center"
                                style={{
                                    fontSize: 14,
                                    width: '200px',
                                    float: 'left',
                                    marginTop: '20px',
                                    marginBottom: '20px'
                                }}
                            >
                            <input type="checkbox" className="form-checkbox" onClick={handleNotice} />
                            <span className="ml-2">공지사항으로 설정</span>
                        </label>
                        <a className="btn text-white bg-indigo-700 hover:bg-indigo-400 items-center"
                            onClick={handleUpload}
                            style={{
                                fontSize: 14,
                                width: '115px',
                                float: 'right',
                                marginTop: '20px',
                                marginBottom: '20px',
                                cursor: 'pointer'
                            }}
                        >
                            <span>게시하기</span>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default KCMicAdminCRUDCreate;