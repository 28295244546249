// KCMic Home 페이지
// Created: 2021.03.21
// Updated: 2021.03.21
// Creator: 박지현 

import React from 'react';

import KCMicHeader from '../../partials/KCMic/KCMicHeader';
import KCMicFooter from '../../partials/KCMic/KCMicFooter';

import KCMicHeroHome from '../../partials/KCMic/Hero/KCMicHeroHome';
import KCMicFeatureBlocks from '../../partials/KCMic/KCMicFeatureBlocks';

let lastChrCookie;

function getCookie(name) {
    var cookieName = name + "=";
    var x = 0;

    while(x <= document.cookie.length) {
        var y = x + cookieName.length;

        if(document.cookie.substring(x, y) === cookieName) {
            if((lastChrCookie = document.cookie.indexOf(";", y)) === -1) {
                lastChrCookie = document.cookie.length;
            }

            return decodeURI(document.cookie.substring(y, lastChrCookie));
        }

        x = document.cookie.indexOf(" ", x) + 1;

        if(x === 0) {
            break;
        }
    }

    return "";
}

function KCMicHome() {

    var result = getCookie('popup2021');

    // console.log(result);
    
    if(result !== 'end') {
        window.open("/kcmic-popup2", "notice", "width=428, height=587, resizable=no, toolbars=no, menubar=no");
    }

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <KCMicHeader />

            {/*  Page content */}
            <main className="flex-grow">

                {/*  Page sections */}
                <KCMicHeroHome />
                <KCMicFeatureBlocks />

            </main>

            {/*  Site footer */}
            <KCMicFooter />

        </div>
    );
}

export default KCMicHome;