// KCMic Admin 자료실(CRUD) 세부 내용
// Created: 2021.03.27
// Updated: 2021.03.27
// Creator: 박지현 

import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import firebase from './../../../fire-admin';
import moment from 'moment';

const StyledTableCell = withStyles((theme) => ({
    head: {
        paddingLeft: '30px',
        backgroundColor: '#6A7372',
        color: theme.palette.common.white,
        fontWeight: 'bold',
        fontSize: 20
    },
    body: {
        fontSize: 14,
        display: 'flex'
    },
}))(TableCell);

const StyledTableEditCell = withStyles((theme) => ({
    head: {
        fontSize: 15,
        disply: 'flex'
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 300,
    },
});

function KCMicAdminCRUDContent(props) {

    const classes = useStyles();
    const history = useHistory();

    const storage = firebase.storage();

    const [info, setInfo] = useState([]);
    const [editMode, setEditMode] = useState(false);

    const [title, setTitle] = useState(props.data.title);   // 게시글 제목
    const [option, setOption] = useState('');               // 게시글 분류
    const [content, setContent] = useState('');             // 게시글 세부내용
    const [writer, setWriter] = useState('');               // 게시글 작성자(담당자)

    const [fileList, setFileList] = useState();
    const [fileNameList, setFileNameList] = useState();

    const [progress, setProgress] = useState(0);

    var urlArr = [];
    var attachArr = [];

    useEffect(() => {
        const list = firebase.firestore().collection('자료실').doc(props.data.id);
        list.onSnapshot((doc) => {
            if (doc.exists) {
                console.log(doc.data());
                setInfo(doc.data());

                setOption(doc.data().option);
                setWriter(doc.data().writer);
                setContent(doc.data().content);
                setFileList(doc.data().fileUrl);
                setFileNameList(doc.data().fileName);
            }
        });
    }, []);

    const handleChange = (e) => {
        if (e.target.files) {
            let file = e.target.files;
            document.getElementById('file-progress').style.display = 'block';

            for (var i = 0; i < file.length; i++) {
                uploadFiles(file[i]);
            }

            setFileList(urlArr);
            setFileNameList(attachArr);
        }
    }

    const uploadFiles = (file) => {
        const uploadTask = storage.ref(`자료실/${file.name}`).put(file);
        uploadTask.on(
            "state_change",
            snapshot => {
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(progress);

                if (progress === 100) {
                    document.getElementById('file-progress').style.display = 'none';
                }
            },
            error => {
                console.log(error);
            },
            () => {
                storage.ref("자료실")
                    .child(file.name)
                    .getDownloadURL()
                    .then(url => {
                        urlArr.push(url);
                        attachArr.push(file.name);
                    })
            }
        );
    }

    const handleDelete = () => {
        firebase.firestore()
            .collection("자료실")
            .doc(props.data.id)
            .delete()
            .then(() => {
                console.log('Document successfully deleted!');
                alert('성공적으로 삭제되었습니다.');
                history.push('/kcmic-admin-crud');
            })
            .catch((err) => {
                console.error("Error removing document: ", err);
            })
    }

    const handleMode = () => {
        setEditMode(true);
    }

    const handleUpdate = () => {
        firebase.firestore()
            .collection("자료실")
            .doc(props.data.id)
            .update({
                title: title,
                option: option,
                writer: writer,
                content: content,
                fileUrl: fileList,
                fileName: fileNameList
            })
            .then(() => {
                console.log("Document successfully updated!");
                setEditMode(false);
            })
    }

    return (
        <section style={{ marginTop: '100px' }}>
            <div className="hidden md:block">
                <div className="max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="pb-12 md:pb-20">
                        <TableContainer component={Paper}>
                            {!editMode ? (

                                // 수정모드 X
                                <Table className={classes.table} aria-label="customized table">

                                    {/* 제목(title) */}
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="left">
                                                {title}
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>

                                        <StyledTableRow>
                                            <StyledTableCell align="left">

                                                {/* 분류(option) */}
                                                <div id="kcmic-crud-creator" style={{ display: 'flex', fontWeight: 'bold', marginLeft: '10px' }}>
                                                    <svg className="w-4 h-4 flex-shrink-0 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                        <path className={`fill-current text-purple-500`} d="M12.071 0L15 2.929v4.142L12.071 10H12V7.101L7.9 3H5v-.071L7.929 0h4.142zm-5 5L10 7.929v4.142L7.071 15H2.929L0 12.071V7.929L2.929 5h4.142z" />
                                                    </svg>
                                                분류
                                            </div>
                                                <div id="kcmic-crud-creator-val" style={{ marginLeft: '15px' }}>
                                                    {option}
                                                </div>

                                                {/* 작성자(writer) */}
                                                <div id="kcmic-crud-creator" style={{ display: 'flex', marginLeft: '50px', fontWeight: 'bold' }}>
                                                    <svg className="w-4 h-4 flex-shrink-0 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                        <path className={`fill-current text-purple-500`} d="M12.071 0L15 2.929v4.142L12.071 10H12V7.101L7.9 3H5v-.071L7.929 0h4.142zm-5 5L10 7.929v4.142L7.071 15H2.929L0 12.071V7.929L2.929 5h4.142z" />
                                                    </svg>
                                                작성자
                                            </div>
                                                <div id="kcmic-crud-creator-val" style={{ marginLeft: '15px' }}>
                                                    {writer}
                                                </div>

                                                {/* 등록일(created) */}
                                                <div id="kcmic-crud-updated" style={{ display: 'flex', marginLeft: '50px', fontWeight: 'bold' }}>
                                                    <svg className="w-4 h-4 flex-shrink-0 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                        <path className={`fill-current text-purple-500`} d="M12.071 0L15 2.929v4.142L12.071 10H12V7.101L7.9 3H5v-.071L7.929 0h4.142zm-5 5L10 7.929v4.142L7.071 15H2.929L0 12.071V7.929L2.929 5h4.142z" />
                                                    </svg>
                                                등록일
                                            </div>
                                                <div id="kcmic-crud-updated-val" style={{ marginLeft: '15px' }}>
                                                    {info.created ? moment(info.created.toDate()).format('YYYY-MM-DD') : ''}
                                                </div>
                                                {/* <div id="kcmic-crud-count" style={{ display: 'flex', marginLeft: '50px', fontWeight: 'bold' }}>
                                                    <svg className="w-4 h-4 flex-shrink-0 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                        <path className={`fill-current text-purple-500`} d="M12.071 0L15 2.929v4.142L12.071 10H12V7.101L7.9 3H5v-.071L7.929 0h4.142zm-5 5L10 7.929v4.142L7.071 15H2.929L0 12.071V7.929L2.929 5h4.142z" />
                                                    </svg>
                                                    조회
                                                </div>
                                                <div id="kcmic-crud-count-val" style={{ marginLeft: '15px' }}>
                                                    {props.data.count}
                                                </div> */}
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell align="left">
                                                <div id="kcmic-crud-creator" style={{ display: 'flex', marginLeft: '10px' }}>
                                                    <div style={{ dispaly: 'flex', flexDirection: 'column', marginRight: '20px' }}>
                                                        <img src={require('./../../../images/floppy-disk.svg')} width="13" height="13" alt="첨부파일"
                                                            style={{ float: 'left', marginTop: '3px', marginRight: '10px' }}
                                                        />
                                                    첨부파일
                                                </div>
                                                    <div style={{ dispaly: 'flex', flexDirection: 'column' }}>
                                                        {fileList && fileNameList ? (
                                                            fileList.map((file, i) =>
                                                                <div key={i} style={{ fontSize: 15 }}>
                                                                    <a href={file}>{fileNameList[i]}</a>
                                                                </div>
                                                            )
                                                        ) : ''}
                                                    </div>
                                                </div>
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        {/* 세부내용(content) */}
                                        <StyledTableRow>
                                            <StyledTableCell align="left">
                                                <div id="kcmic-crud-content"
                                                    style={{
                                                        justifyContent: 'center',
                                                        marginTop: '30px',
                                                        marginBottom: '30px',
                                                        marginLeft: '10px'
                                                    }}
                                                >
                                                    {content}
                                                </div>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>

                            ) : (

                                // 수정모드
                                <Table className={classes.table} aria-label="customized table">
                                    <TableBody>

                                        {/* 제목 */}
                                        <StyledTableRow >
                                            <StyledTableEditCell component="th" scope="row" style={{ width: '100px' }}>
                                                제목
                                            </StyledTableEditCell>
                                            <StyledTableEditCell align="left">
                                                <div className="w-full px-3">
                                                    <input id="crud-title" type="text" className="form-input w-full"
                                                        defaultValue={title}
                                                        onChange={(e) => setTitle(e.target.value)}
                                                    />
                                                </div>
                                            </StyledTableEditCell>
                                        </StyledTableRow>

                                        {/* 게시글 분류 */}
                                        <StyledTableRow >
                                            <StyledTableEditCell component="th" scope="row" style={{ width: '100px' }}>
                                                분류
                                        </StyledTableEditCell>
                                            <StyledTableEditCell align="left">
                                                <div className="w-2/5 md:max-w-full px-3">
                                                    <select id="crud-option" className="form-select w-full"
                                                        defaultValue={option}
                                                        onChange={(e) => setOption(e.target.value)}
                                                    >
                                                        <option>각종서식</option>
                                                        <option>부서업무</option>
                                                        <option>기타</option>
                                                    </select>
                                                </div>
                                            </StyledTableEditCell>
                                        </StyledTableRow>

                                        {/* 첨부파일 */}
                                        <StyledTableRow >
                                            <StyledTableEditCell component="th" scope="row" style={{ width: '100px' }}>
                                                첨부파일
                                            </StyledTableEditCell>
                                            <StyledTableEditCell align="left">
                                                <div className="w-2/5 px-3">
                                                    <input id="crud-file" type="file" multiple className="form-input w-full" required
                                                        onChange={handleChange}
                                                    />
                                                    <div id="file-progress" className="relative pt-1" style={{ display: 'none' }}>
                                                        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
                                                            <div style={{ width: `${progress}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </StyledTableEditCell>
                                        </StyledTableRow>

                                        {/* 내용 */}
                                        <StyledTableRow >
                                            <StyledTableEditCell component="th" scope="row" style={{ width: '100px' }}>
                                                세부내용
                                        </StyledTableEditCell>
                                            <StyledTableEditCell align="left">
                                                <div className="w-full px-3">
                                                    <textarea id="message" rows="4" className="form-textarea w-full"
                                                        defaultValue={content}
                                                        onChange={(e) => setContent(e.target.value)}
                                                    ></textarea>
                                                </div>
                                            </StyledTableEditCell>
                                        </StyledTableRow>

                                        {/* 작성자 */}
                                        <StyledTableRow >
                                            <StyledTableEditCell component="th" scope="row" style={{ width: '100px' }}>
                                                작성자
                                        </StyledTableEditCell>
                                            <StyledTableEditCell align="left">
                                                <div className="w-2/5 px-3">
                                                    <input id="crud-title" type="text" className="form-input w-full"
                                                        defaultValue={writer}
                                                        onChange={(e) => setWriter(e.target.value)}
                                                    />
                                                </div>
                                            </StyledTableEditCell>
                                        </StyledTableRow>

                                    </TableBody>
                                </Table>
                            )}

                        </TableContainer>

                        <Link className="btn text-white bg-indigo-700 hover:bg-indigo-400 items-center"
                            to="/kcmic-admin-crud"
                            style={{
                                fontSize: 14,
                                width: '90px',
                                float: 'left',
                                marginTop: '20px',
                                marginBottom: '20px'
                            }}
                        >
                            <span>목록</span>
                        </Link>
                        {!editMode ? (
                            <div className="btn text-white bg-indigo-700 hover:bg-indigo-400 items-center"
                                onClick={handleMode}
                                style={{
                                    fontSize: 14,
                                    width: '90px',
                                    float: 'right',
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                    cursor: 'pointer'
                                }}
                            >
                                <span>수정</span>
                            </div>
                        ) : (
                            <>
                                <div className="btn text-white bg-pink-700 hover:bg-indigo-400 items-center"
                                    onClick={handleDelete}
                                    style={{
                                        fontSize: 14,
                                        width: '90px',
                                        float: 'right',
                                        marginTop: '20px',
                                        marginBottom: '20px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <span>삭제</span>
                                </div>
                                <div className="btn text-white bg-indigo-700 hover:bg-indigo-400 items-center"
                                    onClick={handleUpdate}
                                    style={{
                                        fontSize: 14,
                                        width: '90px',
                                        float: 'right',
                                        marginTop: '20px',
                                        marginBottom: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <span>저장</span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div className="md:hidden">
                <div className="max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="pb-12 md:pb-20">
                        <TableContainer component={Paper}>
                            {!editMode ? (

                                // 수정모드 X
                                <Table className={classes.table} aria-label="customized table">

                                    {/* 제목(title) */}
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="left"
                                                style={{ fontSize: 16 }}
                                            >
                                                {title}
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>

                                        <StyledTableRow>
                                            <StyledTableCell align="left">

                                                {/* 분류(option) */}
                                                <div id="kcmic-crud-creator" style={{ display: 'flex', fontWeight: 'bold', marginLeft: '10px' }}>
                                                    <svg className="w-4 h-4 flex-shrink-0 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                        <path className={`fill-current text-purple-500`} d="M12.071 0L15 2.929v4.142L12.071 10H12V7.101L7.9 3H5v-.071L7.929 0h4.142zm-5 5L10 7.929v4.142L7.071 15H2.929L0 12.071V7.929L2.929 5h4.142z" />
                                                    </svg>
                                                    분류
                                                </div>
                                                <div id="kcmic-crud-creator-val" style={{ marginLeft: '15px' }}>
                                                    {option}
                                                </div>

                                                {/* 작성자(writer) */}
                                                <div id="kcmic-crud-creator" style={{ display: 'flex', marginLeft: '50px', fontWeight: 'bold' }}>
                                                    <svg className="w-4 h-4 flex-shrink-0 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                        <path className={`fill-current text-purple-500`} d="M12.071 0L15 2.929v4.142L12.071 10H12V7.101L7.9 3H5v-.071L7.929 0h4.142zm-5 5L10 7.929v4.142L7.071 15H2.929L0 12.071V7.929L2.929 5h4.142z" />
                                                    </svg>
                                                    작성자
                                                </div>
                                                <div id="kcmic-crud-creator-val" style={{ marginLeft: '15px' }}>
                                                    {writer}
                                                </div>

                                            </StyledTableCell>

                                            <StyledTableCell align="left">

                                                {/* 등록일(created) */}
                                                <div id="kcmic-crud-updated" style={{ display: 'flex', marginLeft: '10px', fontWeight: 'bold' }}>
                                                    <svg className="w-4 h-4 flex-shrink-0 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                        <path className={`fill-current text-purple-500`} d="M12.071 0L15 2.929v4.142L12.071 10H12V7.101L7.9 3H5v-.071L7.929 0h4.142zm-5 5L10 7.929v4.142L7.071 15H2.929L0 12.071V7.929L2.929 5h4.142z" />
                                                    </svg>
                                                    등록일
                                                </div>
                                                <div id="kcmic-crud-updated-val" style={{ marginLeft: '15px' }}>
                                                    {info.created ? moment(info.created.toDate()).format('YYYY-MM-DD') : ''}
                                                </div>

                                                {/* <div id="kcmic-crud-count" style={{ display: 'flex', marginLeft: '50px', fontWeight: 'bold' }}>
                                                    <svg className="w-4 h-4 flex-shrink-0 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                        <path className={`fill-current text-purple-500`} d="M12.071 0L15 2.929v4.142L12.071 10H12V7.101L7.9 3H5v-.071L7.929 0h4.142zm-5 5L10 7.929v4.142L7.071 15H2.929L0 12.071V7.929L2.929 5h4.142z" />
                                                    </svg>
                                                    조회
                                                </div>
                                                <div id="kcmic-crud-count-val" style={{ marginLeft: '15px' }}>
                                                    {props.data.count}
                                                </div> */}
                                            </StyledTableCell>

                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell align="left">
                                                <div id="kcmic-crud-creator" style={{ display: 'flex', marginLeft: '10px' }}>
                                                    <div style={{ dispaly: 'flex', flexDirection: 'column', marginRight: '20px' }}>
                                                        <img src={require('./../../../images/floppy-disk.svg')} width="13" height="13" alt="첨부파일"
                                                            style={{ float: 'left', marginTop: '3px', marginRight: '10px' }}
                                                        />
                                                    </div>
                                                    <div style={{ dispaly: 'flex', flexDirection: 'column' }}>
                                                        {fileList && fileNameList ? (
                                                            fileList.map((file, i) =>
                                                                <div key={i} style={{ fontSize: 15 }}>
                                                                    <a href={file}>{fileNameList[i]}</a>
                                                                </div>
                                                            )
                                                        ) : ''}
                                                    </div>
                                                </div>
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        {/* 세부내용(content) */}
                                        <StyledTableRow>
                                            <StyledTableCell align="left">
                                                <div id="kcmic-crud-content"
                                                    style={{
                                                        justifyContent: 'center',
                                                        marginTop: '30px',
                                                        marginBottom: '30px',
                                                        marginLeft: '10px'
                                                    }}
                                                >
                                                    {content}
                                                </div>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>

                            ) : (

                                // 수정모드
                                <Table className={classes.table} aria-label="customized table">
                                    <TableBody>

                                        {/* 제목 */}
                                        <StyledTableRow >
                                            <StyledTableEditCell component="th" scope="row" style={{ width: '100px' }}>
                                                제목
                                            </StyledTableEditCell>
                                            <StyledTableEditCell align="left">
                                                <div className="w-full px-3">
                                                    <input id="crud-title" type="text" className="form-input w-full"
                                                        defaultValue={title}
                                                        onChange={(e) => setTitle(e.target.value)}
                                                    />
                                                </div>
                                            </StyledTableEditCell>
                                        </StyledTableRow>

                                        {/* 게시글 분류 */}
                                        <StyledTableRow >
                                            <StyledTableEditCell component="th" scope="row" style={{ width: '100px' }}>
                                                분류
                                            </StyledTableEditCell>
                                            <StyledTableEditCell align="left">
                                                <div className="w-full md:max-w-full px-3">
                                                    <select id="crud-option" className="form-select w-full"
                                                        defaultValue={option}
                                                        onChange={(e) => setOption(e.target.value)}
                                                    >
                                                        <option>각종서식</option>
                                                        <option>부서업무</option>
                                                        <option>기타</option>
                                                    </select>
                                                </div>
                                            </StyledTableEditCell>
                                        </StyledTableRow>

                                        {/* 첨부파일 */}
                                        <StyledTableRow >
                                            <StyledTableEditCell component="th" scope="row" style={{ width: '100px' }}>
                                                첨부파일
                                        </StyledTableEditCell>
                                            <StyledTableEditCell align="left">
                                                <div className="w-full px-3">
                                                    <input id="crud-file" type="file" multiple className="form-input w-full" required
                                                        onChange={handleChange}
                                                    />
                                                    <div id="file-progress" className="relative pt-1" style={{ display: 'none' }}>
                                                        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
                                                            <div style={{ width: `${progress}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </StyledTableEditCell>
                                        </StyledTableRow>

                                        {/* 내용 */}
                                        <StyledTableRow >
                                            <StyledTableEditCell component="th" scope="row" style={{ width: '100px' }}>
                                                세부내용
                                        </StyledTableEditCell>
                                            <StyledTableEditCell align="left">
                                                <div className="w-full px-3">
                                                    <textarea id="message" rows="4" className="form-textarea w-full"
                                                        defaultValue={content}
                                                        onChange={(e) => setContent(e.target.value)}
                                                    ></textarea>
                                                </div>
                                            </StyledTableEditCell>
                                        </StyledTableRow>

                                        {/* 작성자 */}
                                        <StyledTableRow >
                                            <StyledTableEditCell component="th" scope="row" style={{ width: '100px' }}>
                                                작성자
                                        </StyledTableEditCell>
                                            <StyledTableEditCell align="left">
                                                <div className="w-full px-3">
                                                    <input id="crud-title" type="text" className="form-input w-full"
                                                        defaultValue={writer}
                                                        onChange={(e) => setWriter(e.target.value)}
                                                    />
                                                </div>
                                            </StyledTableEditCell>
                                        </StyledTableRow>

                                    </TableBody>
                                </Table>
                            )}

                        </TableContainer>
                        <Link className="btn text-white bg-indigo-700 hover:bg-indigo-400 items-center"
                            to="/kcmic-admin-crud"
                            style={{
                                fontSize: 14,
                                width: '90px',
                                float: 'left',
                                marginTop: '20px',
                                marginBottom: '20px'
                            }}
                        >
                            <span>목록</span>
                        </Link>
                        {!editMode ? (
                            <div className="btn text-white bg-indigo-700 hover:bg-indigo-400 items-center"
                                onClick={handleMode}
                                style={{
                                    fontSize: 14,
                                    width: '90px',
                                    float: 'right',
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                    cursor: 'pointer'
                                }}
                            >
                                <span>수정</span>
                            </div>
                        ) : (
                            <>
                                <div className="btn text-white bg-pink-700 hover:bg-indigo-400 items-center"
                                    onClick={handleDelete}
                                    style={{
                                        fontSize: 14,
                                        width: '90px',
                                        float: 'right',
                                        marginTop: '20px',
                                        marginBottom: '20px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <span>삭제</span>
                                </div>
                                <div className="btn text-white bg-indigo-700 hover:bg-indigo-400 items-center"
                                    onClick={handleUpdate}
                                    style={{
                                        fontSize: 14,
                                        width: '90px',
                                        float: 'right',
                                        marginTop: '20px',
                                        marginBottom: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <span>저장</span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default KCMicAdminCRUDContent;