// KCMic 소개 - 연구원 소개: 연구원소개 인사말
// Created: 2021.03.21
// Updated: 2021.03.21
// Creator: 박지현 

import React from 'react';

function KCMicGreetings() {
    return (
        <section className="relative">

            <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pt-10 pb-12 md:pb-20">
                    <div className="max-w-3xl mx-auto">
                        
                        <article>
                            <iframe width="800" height="800" src={require("./../../images/about2.pdf")}></iframe>
                            
                        </article>

                    </div>
                </div>
            </div>

        </section>
    );
}

export default KCMicGreetings;