// KCMic 자료실(CRUD) 세부 내용
// Created: 2021.03.21
// Updated: 2021.03.27
// Creator: 박지현 

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import firebase from './../../fire-admin';
import moment from 'moment';

const StyledTableCell = withStyles((theme) => ({
    head: {
        paddingLeft: '30px',
        backgroundColor: '#6A7372',
        color: theme.palette.common.white,
        fontWeight: 'bold',
        fontSize: 20
    },
    body: {
        fontSize: 14,
        display: 'flex'
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 300,
    },
});

function KCMicCRUDContent(props) {

    const classes = useStyles();

    const [info, setInfo] = useState([]);

    useEffect(() => {
        const list = firebase.firestore().collection('자료실').doc(props.data.id);
        list.onSnapshot((doc) => {
            if(doc.exists) {
                console.log(doc.data());
                setInfo(doc.data());
            }
        });
    }, []);

    return (
        <section style={{ marginTop: '100px' }}>
            <div className="hidden md:block">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pb-12 md:pb-20">
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                            
                            {/* 제목(title) */}
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="left">
                                        {props.data.title}
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell align="left">

                                        {/* 분류(option) */}
                                        <div id="kcmic-crud-creator" style={{ display: 'flex', fontWeight: 'bold', marginLeft: '10px' }}>
                                            <svg className="w-4 h-4 flex-shrink-0 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <path className={`fill-current text-purple-500`} d="M12.071 0L15 2.929v4.142L12.071 10H12V7.101L7.9 3H5v-.071L7.929 0h4.142zm-5 5L10 7.929v4.142L7.071 15H2.929L0 12.071V7.929L2.929 5h4.142z" />
                                            </svg>
                                            분류
                                        </div>
                                        <div id="kcmic-crud-creator-val" style={{ marginLeft: '15px' }}>
                                            {info.option}
                                        </div>

                                        {/* 작성자(writer) */}
                                        <div id="kcmic-crud-creator" style={{ display: 'flex', marginLeft: '50px', fontWeight: 'bold' }}>
                                            &nbsp;&nbsp;&nbsp;
                                            <svg className="w-4 h-4 flex-shrink-0 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <path className={`fill-current text-purple-500`} d="M12.071 0L15 2.929v4.142L12.071 10H12V7.101L7.9 3H5v-.071L7.929 0h4.142zm-5 5L10 7.929v4.142L7.071 15H2.929L0 12.071V7.929L2.929 5h4.142z" />
                                            </svg>
                                            작성자
                                        </div>
                                        <div id="kcmic-crud-creator-val" style={{ marginLeft: '15px' }}>
                                            {info.writer}
                                        </div>

                                        {/* 등록일(created) */}
                                        <div id="kcmic-crud-updated" style={{ display: 'flex', marginLeft: '50px', fontWeight: 'bold' }}>
                                            <svg className="w-4 h-4 flex-shrink-0 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <path className={`fill-current text-purple-500`} d="M12.071 0L15 2.929v4.142L12.071 10H12V7.101L7.9 3H5v-.071L7.929 0h4.142zm-5 5L10 7.929v4.142L7.071 15H2.929L0 12.071V7.929L2.929 5h4.142z" />
                                            </svg>
                                            등록일
                                        </div>
                                        <div id="kcmic-crud-updated-val" style={{ marginLeft: '15px' }}>
                                            {info.created ? moment(info.created.toDate()).format('YYYY-MM-DD') : ''}
                                        </div>

                                        {/* <div id="kcmic-crud-count" style={{ display: 'flex', marginLeft: '50px', fontWeight: 'bold' }}>
                                            <svg className="w-4 h-4 flex-shrink-0 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <path className={`fill-current text-purple-500`} d="M12.071 0L15 2.929v4.142L12.071 10H12V7.101L7.9 3H5v-.071L7.929 0h4.142zm-5 5L10 7.929v4.142L7.071 15H2.929L0 12.071V7.929L2.929 5h4.142z" />
                                            </svg>
                                            조회
                                        </div>
                                        <div id="kcmic-crud-count-val" style={{ marginLeft: '15px' }}>
                                            {props.data.count}
                                        </div> */}
                                    </StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <StyledTableCell align="left">
                                        <div id="kcmic-crud-creator" style={{ display: 'flex', marginLeft: '10px' }}>
                                            <div style={{ dispaly: 'flex', flexDirection: 'column', marginRight: '20px' }}>
                                                <img src={require('./../../images/floppy-disk.svg')} width="13" height="13" alt="첨부파일"
                                                    style={{ float: 'left', marginTop: '3px', marginRight: '10px' }}
                                                />
                                                첨부파일
                                            </div>
                                            <div style={{ dispaly: 'flex', flexDirection: 'column' }}>
                                                {info.fileUrl && info.fileName ? (
                                                    info.fileUrl.map((file, i) =>
                                                        <div key={i} style={{ fontSize: 15 }}>
                                                            <a href={file}>{info.fileName[i]}</a>
                                                        </div>
                                                    )
                                                ) : ''}
                                            </div>
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>

                                {/* 세부내용(content) */}
                                <StyledTableRow>
                                    <StyledTableCell align="left">
                                        <div id="kcmic-crud-content" 
                                            style={{ 
                                                justifyContent: 'center', 
                                                marginTop: '30px', 
                                                marginBottom: '30px',
                                                marginLeft: '10px'
                                            }}
                                        >
                                            {info.content}
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                    <Link className="btn text-white bg-indigo-700 hover:bg-indigo-400 items-center" 
                        to="/kcmic-crud" 
                        style={{ 
                            fontSize: 14, 
                            width: '90px', 
                            float: 'right', 
                            marginTop: '20px', 
                            marginBottom: '20px' 
                        }}
                    >
                        <span>목록</span>
                    </Link>
                </div>
            </div>
            </div>

            <div className="md:hidden">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pb-12 md:pb-20">
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                            
                            {/* 제목(title) */}
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="left"
                                        style={{ fontSize: 16 }}
                                    >
                                        {props.data.title}
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell align="left">

                                        {/* 분류(option) */}
                                        <div id="kcmic-crud-creator" style={{ display: 'flex', fontWeight: 'bold', marginLeft: '10px' }}>
                                            <svg className="w-4 h-4 flex-shrink-0 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <path className={`fill-current text-purple-500`} d="M12.071 0L15 2.929v4.142L12.071 10H12V7.101L7.9 3H5v-.071L7.929 0h4.142zm-5 5L10 7.929v4.142L7.071 15H2.929L0 12.071V7.929L2.929 5h4.142z" />
                                            </svg>
                                            분류
                                        </div>
                                        <div id="kcmic-crud-creator-val" style={{ marginLeft: '15px' }}>
                                            {info.option}
                                        </div>

                                        {/* 작성자(writer) */}
                                        <div id="kcmic-crud-creator" style={{ display: 'flex', marginLeft: '50px', fontWeight: 'bold' }}>
                                            &nbsp;&nbsp;&nbsp;
                                            <svg className="w-4 h-4 flex-shrink-0 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <path className={`fill-current text-purple-500`} d="M12.071 0L15 2.929v4.142L12.071 10H12V7.101L7.9 3H5v-.071L7.929 0h4.142zm-5 5L10 7.929v4.142L7.071 15H2.929L0 12.071V7.929L2.929 5h4.142z" />
                                            </svg>
                                            작성자
                                        </div>
                                        <div id="kcmic-crud-creator-val" style={{ marginLeft: '15px' }}>
                                            {info.writer}
                                        </div>
                                    </StyledTableCell>

                                    <StyledTableCell align="left">

                                        {/* 등록일(created) */}
                                        <div id="kcmic-crud-updated" style={{ display: 'flex', marginLeft: '10px', fontWeight: 'bold' }}>
                                            <svg className="w-4 h-4 flex-shrink-0 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <path className={`fill-current text-purple-500`} d="M12.071 0L15 2.929v4.142L12.071 10H12V7.101L7.9 3H5v-.071L7.929 0h4.142zm-5 5L10 7.929v4.142L7.071 15H2.929L0 12.071V7.929L2.929 5h4.142z" />
                                            </svg>
                                            등록일
                                        </div>
                                        <div id="kcmic-crud-updated-val" style={{ marginLeft: '15px' }}>
                                            {info.created ? moment(info.created.toDate()).format('YYYY-MM-DD') : ''}
                                        </div>

                                        {/* <div id="kcmic-crud-count" style={{ display: 'flex', marginLeft: '50px', fontWeight: 'bold' }}>
                                            <svg className="w-4 h-4 flex-shrink-0 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <path className={`fill-current text-purple-500`} d="M12.071 0L15 2.929v4.142L12.071 10H12V7.101L7.9 3H5v-.071L7.929 0h4.142zm-5 5L10 7.929v4.142L7.071 15H2.929L0 12.071V7.929L2.929 5h4.142z" />
                                            </svg>
                                            조회
                                        </div>
                                        <div id="kcmic-crud-count-val" style={{ marginLeft: '15px' }}>
                                            {props.data.count}
                                        </div> */}
                                    </StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <StyledTableCell align="left">
                                        <div id="kcmic-crud-creator" style={{ display: 'flex', marginLeft: '10px' }}>
                                            <div style={{ dispaly: 'flex', flexDirection: 'column', marginRight: '20px' }}>
                                                <img src={require('./../../images/floppy-disk.svg')} width="13" height="13" alt="첨부파일"
                                                    style={{ float: 'left', marginTop: '3px', marginRight: '10px' }}
                                                />
                                            </div>
                                            <div style={{ dispaly: 'flex', flexDirection: 'column' }}>
                                                {info.fileUrl && info.fileName ? (
                                                    info.fileUrl.map((file, i) =>
                                                        <div key={i} style={{ fontSize: 15 }}>
                                                            <a href={file}>{info.fileName[i]}</a>
                                                        </div>
                                                    )
                                                ) : ''}
                                            </div>
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>

                                {/* 세부내용(content) */}
                                <StyledTableRow>
                                    <StyledTableCell align="left">
                                        <div id="kcmic-crud-content" 
                                            style={{ 
                                                justifyContent: 'center', 
                                                marginTop: '30px', 
                                                marginBottom: '30px',
                                                marginLeft: '10px'
                                            }}
                                        >
                                            {info.content}
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                    <Link className="btn text-white bg-indigo-700 hover:bg-indigo-400 items-center" 
                        to="/kcmic-crud" 
                        style={{ 
                            fontSize: 14, 
                            width: '90px', 
                            float: 'right', 
                            marginTop: '20px', 
                            marginBottom: '20px' 
                        }}
                    >
                        <span>목록</span>
                    </Link>
                </div>
            </div>
            </div>
        </section>
    );
}

export default KCMicCRUDContent;