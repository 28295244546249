// KCMic Admin 자료실(CRUD) 세부 내용 페이지
// Created: 2021.03.23
// Updated: 2021.03.27
// Creator: 박지현 

import React from 'react';

import KCMicHeader from '../../partials/KCMic/KCMicHeader';
import KCMicFooter from '../../partials/KCMic/KCMicFooter';

import KCMicHeroCRUDWrite from '../../partials/KCMic/Hero/KCMicHeroCRUDWrite';
import KCMicAdminCRUDCreate from '../../partials/KCMic/Admin/KCMicAdminCRUDCreate';

function KCMicAdminCRUDWrite() {

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <KCMicHeader />

            {/*  Page content */}
            <main className="flex-grow">

                {/*  Page sections */}
                <KCMicHeroCRUDWrite />
                <KCMicAdminCRUDCreate />

            </main>

            {/*  Site footer */}
            <KCMicFooter />

        </div>
    );
}

export default KCMicAdminCRUDWrite;